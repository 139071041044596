import jsonApi from "@/service/jsonapiClient";

const cart ={
    namespaced:true,
    state:()=>({
        products:[],
        quantities:[],
        productRefs:[],
        basketRef:'',
        billingAdr:{
            company: '',
            salutation:'',
            firstname: '',
            lastname: '',
            street: '',
            street_no: '',
            postalcode: '',
            city: '',
            country_id: '',
            telephone: '',
            telefax: '',
            email:'',
            custno:'',
            invoicing:0
        },
        shippingAdr:{
            company: '',
            salutation:'',
            firstname: '',
            lastname: '',
            street: '',
            street_no: '',
            postalcode: '',
            city: '',
            country_id: '',
            telephone: '',
            telefax: '',
            email:''
        },
        useShipping:false,
        ppOrderId:'',
        orderId:'',
        paymentInProgress:false,
        orderedProducts:{},
        orderedTotals:{},
        orderedBasketRef:'',
        payPalScriptLoaded:false,
        invoicing:0,
        createError:false,
        createErrors:[]
    }),
    mutations:{
        addProduct(state, productData) {
            const record = state.quantities.find(p => p.id === productData.product.id);
            if (!record) {
                state.quantities.push({
                    id: productData.product.id,
                    qty: productData.qty,
                    sum:productData.qty*productData.product.store_price,
                    gross_sum:productData.qty*productData.product.gross_store_price
                })
                state.productRefs.push({
                    id:productData.product.id,
                    ref:productData.ref
                })
                state.products.push(productData.product)
                } else {
                    record.qty = productData.qty
                    record.sum=productData.qty*productData.product.store_price,
                    record.gross_sum=productData.qty*productData.product.gross_store_price
                }
        },
        removeProduct(state,product){
            const pindex=state.products.findIndex(p=>p.id === product.id)
            const qindex=state.quantities.findIndex(p=>p.id === product.id)
            const rindex=state.productRefs.findIndex(p=>p.id === product.id)
            console.log(pindex)
            if(pindex>-1){
                state.products.splice(pindex,1)
            }
            if(qindex>-1){
                state.quantities.splice(qindex,1)
            }

            if(rindex>-1){
                state.productRefs.splice(rindex,1)
            }

        },
        updateRef(state,productData){
                const record=state.productRefs.find(p => p.id === productData.product.id);
                if(record){
                    record.ref=productData.ref
                }
        },
        setBasketRef(state,ref){
          state.basketRef=ref
        },
        purgeBasket(state){
            state.products=[];
            state.quantities=[];
            state.productRefs=[];
            state.basketRef=''
        },
        setBillingAdr(state,adr){
            for(let prop in state.billingAdr ){
                if (adr[prop]){
                    state.billingAdr[prop]=adr[prop]
                }
            }
        },
        setShippingAdr(state,adr){
            state.shippingAdr=adr
        },
        setUseShipping(state, useit) {
            state.useShipping=useit
        },
        setOrderIds(state,oids){
            state.ppOrderId=oids.pp_order_id
            state.orderId=oids.id
        },
        paymentInProgress(state,pstate){
            state.paymentInProgress=pstate
        },
        saveOrder(state,obasket){
            state.orderedProducts=obasket.oProducts;
            state.orderedTotals=obasket.oTotals
            state.orderedBasketRef=obasket.oBasketRef
        },
        payPalScriptLoaded(state,pstate) {
                state.payPalScriptLoaded=pstate
        },
        invoicing(state,oInvoicing){
            state.invoicing=oInvoicing.invoicing
        },
        createError(state,isError){
            state.createError=isError
        },
        createErrors(state,arrError){
            state.createErrors=arrError
        }
    },
    actions:{
        addProduct({commit},productData){
            let ocommit
            let qty
            if(!productData.product){
                ocommit={
                    product:productData,
                    qty:1,
                    ref:''
                }
               // console.log(ocommit)
            }
            else{
                ocommit=productData
                ocommit.ref=''
               // console.log(ocommit)
            }
            if(!isNaN(ocommit.qty)){
                qty=Math.abs(parseInt(ocommit.qty))
            }
            if(qty>0){
                commit('addProduct',ocommit)
            }
            else if(qty===0){
                commit('removeProduct',ocommit.product)
            }
        },
        removeProduct({commit}, product) {
            commit('removeProduct',product)
        },
        updateRef({commit},productData){
            commit('updateRef',productData)
        },
        setBillingAdr({commit},adr){
            commit('setBillingAdr',adr)
        },
        setShippingAdr({commit},adr){

            commit('setShippingAdr',adr)
        },
        setUseShipping({commit},useit){
            commit('setUseShipping',useit)
        },
        setBasketRef({commit},ref){
          commit('setBasketRef',ref)
        },
        createOrder({commit,state,getters},invoicing){
            commit('createError',false)
            commit('createErrors',[])
            commit('paymentInProgress',true)
            commit('invoicing',invoicing)
            commit('saveOrder',{oProducts:{},oTotals:{},oBasketRef:''})
            let orderObj={
                billingAdr:state.billingAdr,
                orderPositions: state.quantities,
                positionRefs:state.productRefs,
                orderRef:state.basketRef
            }
            if(state.useShipping){
                orderObj.shippingAdr=state.shippingAdr
            }
            orderObj.useShipping=state.useShipping
            orderObj.totals=getters.totals
            orderObj.invoicing=getters.invoicing
            //console.log(orderObj)
            return jsonApi.create('etsOrder',orderObj).then(res=>{
                commit('setOrderIds',{pp_order_id:res.data.pp_order_id,id:res.data.id})
                //console.log(res)

            }).catch(err=>{
               console.log(err);
                commit('createError',true);
                if(err.status===422){
                    let errDescriptions=[]
                    for( const [,value] of Object.entries(err.detail)){
                        if(value.isArray){
                            value.forEach(element => errDescriptions.push(element))
                        }
                        else{
                            errDescriptions.push(value)
                        }
                    }
                    commit('createErrors',errDescriptions)
                }
                commit('setOrderIds',{pp_order_id:'',id:''})
                commit('paymentInProgress',false)
            });

        },
        captureOrder({commit,getters},ppData){
            //console.log(ppData)
            return new Promise((resolve,reject)=>{
                jsonApi.update('etsOrder',{
                    id:getters.getOrderId,
                    pp_order_id: ppData.orderID
                }).then(res=>{
                    commit('setOrderIds',{pp_order_id:res.data.pp_order_id,id:res.data.id})
                    commit('saveOrder',{oProducts:getters.cartProducts,oTotals:getters.totals,oBasketRef:getters.getBasketRef})
                    commit('purgeBasket')
                    commit('paymentInProgress',false)
                    //console.log(res)
                    resolve({
                        committed:true,
                        retry:false
                    })
                }).catch(err=>{
                    commit('paymentInProgress',false)
                    reject(err)
                    console.log(err)
                })
            })

        },
        cancelOrder({commit},ppData){
            return jsonApi.destroy('etsOrder',ppData.orderID).then(()=>{
                commit('setOrderIds',{pp_order_id:'',id:''})
                commit('paymentInProgress',false)
            }).catch(err=>{
                console.log(err)
                commit('setOrderIds',{pp_order_id:'',id:''})
                commit('paymentInProgress',false)
            })
        },
        payPalScriptLoaded({commit}) {
            commit('payPalScriptLoaded',true)
        }
    },
    getters:{
        cartProducts:state=> {
            return state.quantities.map(({id, qty,sum,gross_sum},index) => {
                const product=state.products.find(p=>p.id===id)
                const pref=state.productRefs.find(pr=>pr.id===id)
                //console.log(pref)
                return {
                    product,
                    qty,
                    sum,
                    ref:pref.ref,
                    gross_sum,
                    index:(index+1)*10
                }
            })
        },
        cartProductQty:(state)=>(product)=>{
            const record = state.quantities.find(p => p.id === product.id);
            if(record){
                return record.qty
            }
            return 0
        },
        totals:(state,getters,rootState,rootGetters)=>{
            const inits= rootGetters['inits/storeConfig']
            const tax =parseInt(inits.tax)
            let shipping =parseFloat(inits.store_shipping)
            let handling=parseFloat(inits.store_handling)
            const handling_threshhold=parseFloat(inits.store_handling_threshhold)
            const shipping_threshhold=parseFloat(inits.store_shipping_threshhold)
            let product_total=0
            let product_grosstotal=0
            let total=0
            let grosstotal=0
            let totaltax=0
            state.quantities.forEach(q=>{
                product_total+=q.sum
                product_grosstotal+=q.gross_sum
            })
            if((handling_threshhold>0)&&(product_total>=handling_threshhold)){
                handling=0
            }
            if((shipping_threshhold>0)&&(product_total>=shipping_threshhold)){
                shipping=0
            }

            total=product_total+handling+shipping
            grosstotal=total*(1+tax/100)
            totaltax=grosstotal-total
            return {
                product_total,
                product_grosstotal,
                total,
                grosstotal,
                shipping,
                handling,
                tax,
                totaltax,
                handling_threshhold,
                shipping_threshhold
            }
        },
        getBasketRef:(state)=>{
          return state.basketRef
        },
        getPpOrderId: (state) =>{
            return state.ppOrderId
        },
        getOrderId:(state)=>{
            return state.orderId
        },
        paymentInProgress:(state)=>{
            return state.paymentInProgress
        },
        billingAdr:(state)=>{
            return state.billingAdr
        },
        shippingAdr:(state)=>{
            return state.shippingAdr
        },
        useShipping:(state)=>{
            return state.useShipping
        },
        orderedProducts:(state)=>{
            return state.orderedProducts
        },
        orderedTotals:(state)=>{
            return state.orderedTotals
        },
        orderedBasketRef:(state)=>{
          return state.orderedBasketRef
        },
        payPalScriptLoaded:(state)=>{
            return state.payPalScriptLoaded
        },
        invoicing:(state) =>{
            return state.invoicing
        },
        createError:(state)=>{
            return state.createError
        },
        createErrors:(state)=>{
            return state.createErrors
        }
    }
}
export default cart