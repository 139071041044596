<template>
<div  id="paypal-button-container" ref="paypal"></div>
</template>

<script>

export default {
    name: "paypal-buttons",

    data() {
        return {
        }
    },
    mounted:function(){
        if(this.payPalScriptLoaded){
            this.setUpPayPalButtons()
        }
    },
    methods:{

        setUpPayPalButtons :function(){
            window.paypal
                .Buttons({
                    style: {
                        color:  'blue',
                        shape:  'pill',
                        label:  'pay',
                        height: 40
                    },
                    enableStandardCardFields: true,
                    createOrder: () => {
                        //this.showButtons=false;
                        return this.$store.dispatch('cart/createOrder',{invoicing:0}).then(()=>{
                          if(this.$store.getters['cart/createError']){
                            throw new Error('cart create error');
                          }
                          else{
                            const id= this.$store.getters['cart/getPpOrderId']
                            return id
                          }
                        })
                            /*.catch(()=>{
                            callback([])
                        })*/

                    },
                    onApprove:async  (data,actions) => {
                      return this.$store.dispatch('cart/captureOrder',data).then((res)=>{
                          if(res.committed) {
                              this.$router.push('/basket/order-confirmation')
                          }
                          //alert('captured')
                      }).catch(err=>{
                          const orderData=JSON.parse(err.title)
                          const errorDetail = Array.isArray(orderData.details) && orderData.details[0];
                          if (errorDetail && errorDetail.issue === 'INSTRUMENT_DECLINED') {
                              return actions.restart(); // Recoverable state, per:
                              // https://developer.paypal.com/docs/checkout/integration-features/funding-failure/
                          }
                          else{
                              this.showCancel('Die Transaktion konnte leider nicht bearbeitet werden.')
                          }

                      })


                    },
                    onError: err => {
                        console.log(err);
                        if(this.$store.getters['cart/createError']){
                          let strErr='';
                          let arrErr=this.$store.getters['cart/createErrors'];
                          if(arrErr.length>0){
                            arrErr.forEach(str=>strErr+=str+'<br>')
                          }
                          this.showCancel(strErr)

                        }
                        else{
                          this.showCancel('Es ist ein Fehler aufgetreten')
                        }
                    },
                    onShippingChange: function(data, actions) {
                        // Reject non-US addresses
                        if (data.shipping_address.country_code !== 'DE') {
                            return actions.reject();
                        }
                    },
                    onCancel: async (data) =>{

                         this.$store.dispatch('cart/cancelOrder',data).then(()=>{
                                this.showCancel('Zahlung abgebrochen')
                        })

                    }
                })
                .render(this.$refs.paypal);
        },
        showCancel(msg){
            this.boxOne = ''
            const msgHtml=this.$createElement('div',{domProps:{innerHTML:msg+'<br>Ihre Bestellung wurde nicht ausgeführt'}})
            this.$bvModal.msgBoxOk([msgHtml],{
              title:'Fehler',
              okVariant:'danger',
              size: 'sm',
              buttonSize: 'sm',
              headerClass: 'p-2 border-bottom-0',
              footerClass: 'p-2 border-top-0',
              centered: true
            })
                .then(value => {
                    this.boxOne = value
                })
        }
    },
    computed:{
        cartProducts(){
            return this.$store.getters['cart/cartProducts']
        },
        cartTotals(){
            return this.$store.getters['cart/totals']
        },
        payPalScriptLoaded(){
            return this.$store.getters['cart/payPalScriptLoaded']
        }

    },
    watch:{
        payPalScriptLoaded(oldVal,newVal){
            if(oldVal===false&&newVal===true){
                this.setUpPayPalButtons();
            }
        }
    }
}
</script>

<style scoped>
    @media screen and (max-width: 400px) {
        #paypal-button-container {
            width: 100%;
            margin:auto
        }
    }

    /* Media query for desktop viewport */
    @media screen and (min-width: 400px) {
        #paypal-button-container {
            width: 250px;
            margin:auto
        }
    }
    #ppoverlay{
        z-index: 10000;
    }
</style>