<template>

    <b-navbar id="navBar"  toggleable="md" type="dark" variant="dark">
     <b-navbar-brand class="showBrand" :to="{name:'home'}" exact>Ersatzteilshop</b-navbar-brand>
          <b-navbar-toggle   target="nav-collapse"></b-navbar-toggle>
          <b-collapse id="nav-collapse" v-model="isCollapseOpen"  is-nav>
                <b-navbar-nav align="left">
                  <b-nav-item class="showHome" :to="{name:'home'}" exact>Home</b-nav-item>
                    <b-nav-item-dropdown text="Suche">
                        <b-dropdown-item :to="{name:'search'}">Gerätesuche</b-dropdown-item>
                        <b-dropdown-item :to="{name:'psearch'}">Ersatzteilsuche</b-dropdown-item>
                    </b-nav-item-dropdown>
                    <b-nav-item :to="{name:'exploded-view'}" >Zeichnung</b-nav-item>
                    <!-- Warenkorb({{cartProducts.length}}) :to="{name:'basket'}" -->
                    <b-nav-item-dropdown v-show='!noCheckout' ref="basketDropdown" :text="'Warenkorb('+cartProducts.length+')'"  >
                        <b-dropdown-text tag="div" style="width: 300px">
                            <top-basket></top-basket>
                        </b-dropdown-text>
                        <b-dropdown-item type="dark" :to="{name:'basket'}">zum Warenkorb</b-dropdown-item>
                    </b-nav-item-dropdown>
                    <b-nav-item  v-show='!noCheckout' :to="{name:'about'}" >Über</b-nav-item>
                   <!-- <b-nav-item :to="{name:'order-confirmation'}" >conf</b-nav-item> -->
                </b-navbar-nav>

                <!-- Right aligned nav items -->
                <b-navbar-nav >
                 <combined-search-bar ></combined-search-bar>
                </b-navbar-nav>
            </b-collapse>
    </b-navbar>
</template>

<script>
    import TopBasket from "@/components/TopBasket";
    import CombinedSearchBar from "@/components/CombinedSearchBar";

    export default {
        name: "top-header",
        components: {CombinedSearchBar, TopBasket},
        data(){
          return {
            isCollapseOpen: false
          }
        },
        computed:{
            cartProducts(){
                    return this.$store.getters['cart/cartProducts']
            },
            noCheckout(){
                return this.$store.getters['inits/noCheckout'];
            }
        },
        watch:{
            cartProducts(newCardProducts,oldCardProducts){
                if(newCardProducts.length!==oldCardProducts.length) {
                    if(['basket','check-out','order-confirmation'].indexOf(this.$route.name)===-1) {//check-out,order-confirmation
                        this.$refs.basketDropdown.show()
                    }
                }
            },
            $route(){
              if(this.isCollapseOpen) {
                this.$root.$emit('bv::toggle::collapse', 'nav-collapse')
              }
            }
        }
    }
</script>

<style>
    .dropdown-item.active, .dropdown-item:active{
        background-color: black !important;
    }

    @media (min-width: 768px){
      .showBrand{
        display: none;
      }
    }
    @media (min-width: 1200px){
      .showBrand{
        display: block;
      }
    }
    @media (max-width: 992px){
      .showHome{
        display: none;
      }
    }
    @media (max-width: 768px){
      .showHome{
        display: block;
      }
    }
</style>

