import jsonApi from "../../service/jsonapiClient";

const search={
    namespaced:true,
    state:()=>({
        searchQuery:{
            sterm:'',
            sid:null,
            sname:'',
            resultLoaded:false,
            page:1
        },
        psearchQuery:{
            sterm:'',
            sid:null,
            sname:'',
            resultLoaded:false,
            page:1
        },
        machineResults:[],
        productResults:[],
        loadState:0,
        ploadState:0

    }),
    mutations:{
        machineResults(state,data){
            state.machineResults=data
            state.searchQuery.resultLoaded=true

        },
        productResults(state,data){
            state.productResults=data
            state.psearchQuery.resultLoaded=true

        },
        searchQuery(state,query){
            let squery=state.searchQuery
            if(query.stype&&query.stype==='product'){
                squery=state.psearchQuery
            }
            squery.sterm=query.sterm
            squery.sid=query.sid
            squery.sname=query.sname
            squery.resultLoaded=false
            squery.page=1
        },
        loadState(state,astate){
            state.loadState=astate
        },
        ploadState(state,astate){
            state.ploadState=astate
        },
        currentPage(state,page){
            let squery=state.searchQuery
            if(page.stype&&page.stype==='product'){
                squery=state.psearchQuery
            }
            squery.page=page.page
            squery.resultLoaded=false
        }
    },
    actions:{
        searchMachine({commit,state}){
            if(state.searchQuery.sterm.length>0||state.searchQuery.sid) {
                commit("loadState", 1)
                return jsonApi.all('machine').get({
                    mname: state.searchQuery.sterm,
                    sid: state.searchQuery.sid,
                    page: state.searchQuery.page
                }).then(res => {
                    commit('machineResults', res)
                    commit("loadState", 2)
                }).catch(error => {
                    console.log(error)
                    commit('machineResults', [])
                    commit("loadState", 3)
                })
            }
            else{
                commit('machineResults', [])
                commit("loadState", 0)
            }
        },
        searchProduct({commit,state}){
            if(state.psearchQuery.sterm.length>0||state.psearchQuery.sid) {
                commit("ploadState", 1)
                return jsonApi.all('product').get({
                    pname: state.psearchQuery.sterm,
                    sid: state.psearchQuery.sid,
                    page: state.psearchQuery.page,
                    scope:'store',
                    categories:'hans-sauer/ersatzteile',
                    include:'supplier'
                }).then(res => {
                    commit('productResults', res)
                    commit("ploadState", 2)
                }).catch(error => {
                    console.log(error)
                    commit('productResults', [])
                    commit("ploadState", 3)
                })
            }
            else{
                commit('productResults', [])
                commit("ploadState", 0)
            }
        },
        searchQuery({commit,rootGetters},searchData){
            const suppliers=rootGetters['inits/suppliers'];
            const asupplier= suppliers.find(supplier=>supplier.id===searchData.sid)
            if(asupplier){
                searchData.sname=asupplier.name
            }
            else{
                searchData.sname=''
            }
            commit('searchQuery',searchData)
        },
        currentPage({commit},page){
            commit('currentPage',page)
        }
    },
    getters:{
        machineResults:state=>{
            return state.machineResults
        },
        searchQuery:state=>{
            return state.searchQuery
        },
        loadState:state=>{
            return state.loadState
        },
        productResults:state=>{
            return state.productResults
        },
        psearchQuery:state=>{
            return state.psearchQuery
        },
        ploadState:state=>{
            return state.ploadState
        }
    }
}

export default search