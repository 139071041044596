<template>
    <b-card title="Bestellübersicht" :sub-title="basketRef">

        <!--Card content-->
        <b-card-body>

            <dl class="row"
                v-for="value in cartProducts"
                :key="value.product.id"
            >
                <dd class="col-sm-8">
                   {{value.product.name}}
                    <br>{{value.product.sku}}
                     <br>Menge:{{value.qty}}
                  <br>Referenz:{{value.ref}}
                </dd>
                <dd class="col-sm-4">{{formatMoney(value.sum)}}</dd>
            </dl>

            <hr>
            <dl class="row">
                <dd class="col-sm-8">
                    Warenwert
                </dd>
                <dd class="col-sm-4">
                    {{formatMoney(cartTotals.product_total)}}
                </dd>
            </dl>
            <dl class="row">
                <dd class="col-sm-8">
                    Versand
                </dd>
                <dd class="col-sm-4">
                    {{formatMoney(cartTotals.shipping)}}
                </dd>
            </dl>
            <dl class="row">
                <dd class="col-sm-8">
                    Minder- <br>mengenzuschlag
                </dd>
                <dd class="col-sm-4">
                    {{formatMoney(cartTotals.handling)}}
                </dd>
            </dl>
            <dl class="row">
                <dd class="col-sm-8">
                    Mwst ({{cartTotals.tax}}%)
                </dd>
                <dd class="col-sm-4">
                    {{formatMoney(cartTotals.totaltax)}}
                </dd>
            </dl>

            <dl class="row">
                <dt class="col-sm-8">
                    Insgesamt
                </dt>
                <dt class="col-sm-4">
                    {{formatMoney(cartTotals.grosstotal)}}
                </dt>
            </dl>
        </b-card-body>

    </b-card>
</template>

<script>
    import helpers from "../service/helpers";
export default {
    name: "side-basket",
    methods:{
        formatMoney(value){
            return helpers.formatMoney(value)+'€'
        },
    },
    computed:{
        cartProducts(){
            return this.$store.getters['cart/cartProducts']
        },
        cartTotals(){
            return this.$store.getters['cart/totals']
        },
      basketRef(){
        return this.$store.getters['cart/getBasketRef']
      }
    }

}
</script>

<style scoped>

</style>