<template>
   <!-- <b-card title="Bestellübersicht">-->

        <!--Card content-->
       <!-- <b-card-body>-->
<b-container >
    <b-row class="w-100"
            v-for="value in cartProducts"
            :key="value.product.id"
    >
        <b-col class="text-left"  cols="10" >
            {{value.product.name}}<br>
            {{value.product.sku}}

        </b-col>
        <b-col  class="text-right">
           {{value.qty}}
        </b-col>
    </b-row>
      <!--  </b-card-body>-->

   <!-- </b-card>-->
</b-container>
</template>

<script>
export default {
    name: "top-basket",
    methods:{

    },
    computed:{
        cartProducts(){
            return this.$store.getters['cart/cartProducts']
        }
    }

}
</script>

<style scoped>

</style>