import JsonApi from 'devour-client'
//console.log(process.env.VUE_APP_API_SERVER)
const jsonApi = new JsonApi({apiUrl:process.env.VUE_APP_API_SERVER})

jsonApi.define('supplier',{
    name:'',
    weeenr:'',
    companyname:'',
    address1:'',
    address2:'',
    address3:'',
    address4:'',
    email:'',
    gpsrmemo:'',
    gpsrlink:''
},{type:'supplier'});



jsonApi.define('product',{
    name:'',
    sku:'',
    supplier_sku:'',
    price:'',
    active:'',
    available:'',
    delivery_days:'',
    store_price:'',
    gross_store_price:'',
    hotspots:{
        jsonApi:'hasMany',
        type:'hotspot'
    },
    supplier:{
        jsonApi:'hasOne',
        type:'supplier'
    }
},{type:'product'});

jsonApi.define('hotspot',{
    posx:'',
    posy:'',
    posnum:'',
    cinfo:'',
    product_id:'',
    product:{
        jsonApi:'hasOne',
        type:'product'
    },
    drawing:{
        jsonApi:'hasOne',
        type:'drawing'
    }
},{type:'hotspot'});

jsonApi.define('drawing',{
    image:'',
    scale_x:'',
    scale_y:'',
    list_num:'',
    hotspot:{
        jsonApi:'hasMany',
        type:'hotspot'
    },
    machine:{
        jsonApi:'hasOne',
        type:'machine'
    }
},{type:'drawing'});

jsonApi.define('machine', {
    name: '',
    manufacturerType: '',
    webUrl:'',
    hasDocs:'',
    docsUrl:'',
    pdfUrl:'',
    public:'',
    supplier:{
        jsonApi:'hasOne',
        type:'supplier'
    },
    drawing:{
        jsonApi:'hasMany',
        type:'drawing'
    }
},{type:'machine'});

jsonApi.define('storeConfig',{
    store_authorized:'',
    tax:'',
    store_margin: '',
    store_paypal_email:'',
    user_id: '',
    customer_id: '',
    company:'',
    store_shipping:'',
    store_handling:'',
    store_handling_threshhold:'',
    store_shipping_threshhold:'',
    paypal_client_id:'',
    paypal_mode:'',
    invoicing:''

},{type:'storeConfig'})

/*jsonApi.define('etsOrder',{
    'del_email':'',
    'del_salutation':'',
    'del_company':'',
    'del_title':'',
    'del_firstname':'',
    'del_lastname':'',
    'del_street':'',
    'del_street_no':'',
    'del_postalcode':'',
    'del_city':'',
    'del_country_id':'',
    'del_telephone':'',
    'billing_email':'',
    'billing_salutation':'',
    'billing_company':'',
    'billing_title':'',
    'billing_firstname':'',
    'billing_lastname':'',
    'billing_street':'',
    'billing_street_no':'',
    'billing_postalcode':'',
    'billing_city':'',
    'billing_country_id':'',
    'billing_telephone':'',
    orderPositions:{
        jsonApi:'hasMany',
        type:'orderPosition'
    }
},{type:'etsOrder'})*/

jsonApi.define('etsOrder',{
    shippingAdr:{},
    billingAdr:{},
    orderPositions:[],
    positionRefs:[],
    orderRef:'',
    useShipping:'',
    invoicing:'',
    totals:{},
    pp_order_id:''
})

/*jsonApi.define('orderPosition',{
    sku:'',
    qty:''
},{type:'orderPosition'})*/
const errorMiddleware = {
    name: 'nothing-to-see-here',
    error: function (payload) {
        return  payload.response.data.errors;
    }
};
jsonApi.headers['Accept'] = 'application/vnd.sauer.v1+json';
jsonApi.replaceMiddleware('errors', errorMiddleware);

export default jsonApi
