import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import MachineSearch from "@/views/MachineSearch";
import ProductSearch from "@/views/ProductSearch";
import ExplodedView from "@/views/ExplodedView";
import Layout from "@/layout/Layout";
import Basket from "@/views/Basket";
import About from "@/views/About";
import CheckOut from "../views/CheckOut";
import OrderConfirmation from "@/views/OrderConfirmation";


Vue.use(VueRouter)

const routes = [
  {
    path:'/',
    component:Layout,
    children:[
      {
        path: '',
        name: 'home',
        component: Home,
        props:{company:'Test'}
      },
      {
        path:'/search',
        name:'search',
        component: MachineSearch
      },
      {
        path:'/psearch',
        name:'psearch',
        component: ProductSearch
      },
      {
        path:'/exploded-view',
        name:'exploded-view',
        component: ExplodedView,
        props:true
      },
      {
        path:'/basket',
        name:'basket',
        component: Basket,
      },
      {
        path: '/about',
        name: 'about',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        //component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
        component: About
      },
      {
        path:'/basket/checkout',
        name:'check-out',
        component:CheckOut
      },
      {
        path:'/basket/order-confirmation',
        name:'order-confirmation',
        component: OrderConfirmation
      }
    ]
  }
]

const router = new VueRouter({
  mode:'abstract',
  linkActiveClass: 'active',
  linkExactActiveClass:'active',
  routes
})

export default router
