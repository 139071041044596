import jsonApi from "../../service/jsonapiClient";

const explodedView={
    namespaced:true,
    state:()=>({
        machine: {},
        loadState:0,
        machineId:null,
        drawingIndex:null,
        drawing:{},
        hotspotIndex:null,
        hotspot:{},
       // hotspots:[],
        zoom:1,
        hotspotVisibleInfo:false,
        hotspotVisibleOrder:false
    }),
    mutations:{
        machine(state,data){
            state.machine=data
            state.hotspots=[]
            /*data.drawing.forEach(dr=>{
                dr.hotspot.forEach(hs=>{
                    state.hotspots.push({id:hs.id,list_num:dr.list_num,productSku:hs.product.sku,productName:hs.product.name,posnum:hs.posnum})
                })

            })
            console.log(state.hotspots)*/
        },
        drawingIndex(state,index){
            let valid=false
            if(Object.prototype.hasOwnProperty.call(state.machine,'drawing')){
                if(state.machine.drawing[index]){
                    valid=true
                    state.drawingIndex=index
                    state.drawing=state.machine.drawing[index]
                }
            }
            if(!valid){
                state.drawingIndex=null
                state.drawing={}
            }
        },
        hotspotIndex(state,index){
            let valid=false
            if(Object.prototype.hasOwnProperty.call(state.drawing,'hotspot')) {
                if (state.drawing.hotspot[index]) {
                    valid=true
                    state.hotspotIndex = index
                    state.hotspot = state.drawing.hotspot[index]
                }
            }
            if(!valid){
                state.hotspotIndex=null
                state.hotspot={}
            }
        },
        machineId(state,id){
            state.machineId=id
        },
        zoom(state,change){
            let newzoom=state.zoom+(change);
            newzoom=Math.round((newzoom + Number.EPSILON) * 10) / 10
            if(newzoom<=2&&newzoom >=0.2){
                state.zoom=newzoom
            }
        },
        hotspotVisibleInfo(state,visible) {
            state.hotspotVisibleInfo=Boolean(visible)
        },
        hotspotVisibleOrder(state,visible) {
            state.hotspotVisibleOrder=Boolean(visible)
        },
        loadState(state,lstate){
            state.loadState=lstate
        }
    },
    actions:{
        machine({commit,state}){

            if(state.machineId) {
                commit('loadState',1)
                //return jsonApi.one('machine', state.machineId).get({include: 'drawing,drawing.hotspot:scope(store),drawing.hotspot.product:scope(store)'}).then(res => {
                return jsonApi.one('machine', state.machineId).get({include: 'drawing,drawing.hotspot:scope(store),drawing.hotspot.product:scope(store)'}).then(res => {
                    if(Array.isArray(res.data.drawing)&&res.data.drawing.length>0) {
                        commit('machine', res.data)
                        commit('drawingIndex', 0)
                        commit('hotspotIndex', 0)
                        commit('hotspotVisibleInfo', false)
                        commit('hotspotVisibleOrder', false)
                        commit('loadState', 2)
                        //console.log('drawing',res.data.drawing)
                    }
                    else{
                        commit('loadState',3)
                    }

                }).catch(err => {
                    console.log(err)
                    commit('machine', {})
                    commit('drawingIndex',null)
                    commit('hotspotIndex',null)
                    commit('hotspotVisibleInfo',false)
                    commit('hotspotVisibleOrder',false)
                    commit('loadState',3)
                })
            }
            else{
                commit('machine', {})
                commit('drawingIndex',null)
                commit('hotspotIndex',null)
                commit('hotspotVisibleInfo',false)
                commit('hotspotVisibleOrder',false)
                commit('loadState',0)
            }
        },
        drawingIndex({commit},index){
            commit('drawingIndex',index)
            commit('hotspotIndex',null)
            commit('hotspotVisibleInfo',false)
            commit('hotspotVisibleOrder',false)
        },
        hotspotIndex({commit},index){
            commit('hotspotIndex',index)
        },
        machineId({commit},id){
            //console.log('mid',id)
            commit('machineId',id)
        },
        zoom({commit},change){
            commit('zoom',change)
        },
        hotspotVisibleInfo({commit},visible){
            commit('hotspotVisibleInfo',visible)
        },
        hotspotVisibleOrder({commit},visible){
            commit('hotspotVisibleOrder',visible)
        }

    },
    getters:{
        machine:state=>{
            return state.machine
        },
        machineId:state=>{
            return state.machineId
        },
        hotspotIndex:state=> {
            return state.hotspotIndex
        },
        hotspot:state=> {
            return state.hotspot
        },
        drawingIndex:state=>{
            return state.drawingIndex
        },
        drawing:state=>{
            return state.drawing
        },
        zoom:state=>{
            return state.zoom
        },
        drawingScaleX:state=>{
            return (state.drawing.scale_x*state.zoom).toFixed()+'px'
        },
        drawingScaleY:state=>{
            return (state.drawing.scale_y*state.zoom).toFixed()+'px'
        },
        hotspotTop:state=>{
            return (state.hotspot.posy*state.zoom).toFixed()+'px'
        },
        hotspotLeft:state=>{
            return (state.hotspot.posx*state.zoom).toFixed()+'px'
        },
        hotspotVisibleInfo:state=>{
            return state.hotspotVisibleInfo
        },
        hotspotVisibleOrder:state=>{
            return state.hotspotVisibleOrder
        },
        loadState:state=>{
            return state.loadState
        },
        getDrawingIndexFromNum:(state)=>(list_num)=>{
           return state.machine.drawing.findIndex(d=>d.list_num===list_num);
        },
        getHotspotIndexFromPos:(state)=>(posnum)=>{
            return state.drawing.hotspot.findIndex(h=>h.posnum===posnum)
        },
        hotspots:(state)=>{
            let hotspots=[]
            state.machine.drawing.forEach(dr=>{
                dr.hotspot.forEach(hs=>{
                    hotspots.push({id:hs.id,list_num:dr.list_num,productSku:hs.product.sku,productName:hs.product.name,posnum:hs.posnum})
                })

            })
            return hotspots
        },
        getHotspotInfoById:(state)=>(hid)=>{
            const dlen=state.machine.drawing.length
            let drawingIndex=-1
            let hotspotIndex=-1
            for(let i=0;i<dlen;i++){
                hotspotIndex=state.machine.drawing[i].hotspot.findIndex(h=>h.id===hid)
                if(hotspotIndex>-1){
                    drawingIndex=i
                    break;
                }
            }
            return {drawingIndex:drawingIndex,hotspotIndex:hotspotIndex}
        }

    }
}

export default explodedView
