<template>
    <b-container>
    <b-card sub-title="Bestellung erfasst" title="Vielen Dank für Ihre Bestellung!">
        <b-card-body>
            <b-row>
                <b-col>
                    <b-card title="Rechnungsadresse">
                        <b-card-body>
                            <b-card-text>
                                <b-table
                                        stacked=""
                                        :items="[billingAdr]"
                                        :fields="adrfields"
                                >

                                </b-table>
                            </b-card-text>
                        </b-card-body>
                </b-card>
                </b-col>
                <b-col>
                    <b-card title="Versandadresse">
                        <b-card-sub-title v-if="!useShipping">Reschnungsadresse ist Versandadresse</b-card-sub-title>
                        <b-card-body>
                            <b-card-text>
                                <b-table
                                        v-if="useShipping"
                                        stacked=""
                                        :items="[shippingAdr]"
                                        :fields="adrfields"
                                >
                                </b-table>
                            </b-card-text>
                        </b-card-body>
                    </b-card>
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <b-card title="Bestellung" :sub-title="cartRef">
                        <b-card-body>
                            <b-card-text>
                                <b-row class="mr-0 ml-0">
                                    <b-col>
                                        <b-table
                                            :items="cartProducts"
                                            :fields="basketfields"
                                            id="basket-table"
                                            primary-key="index"
                                            stacked="sm"
                                        >
                                        </b-table>
                                    </b-col>
                                </b-row>
                                <b-row class="ml-0 mr-0">
                                    <b-col sm="7" offset-sm="5" >

                                        <b-table
                                                stacked=""
                                                :items="[cartTotals]"
                                                :fields="totalfields"
                                        >
                                        </b-table>
                                    </b-col>
                                </b-row>
                            </b-card-text>
                        </b-card-body>
                    </b-card>
                </b-col>
            </b-row>
        </b-card-body>
    </b-card>
    </b-container>
</template>

<script>
    import helpers from "../service/helpers";
export default {
    name: "OrderConfirmation",
    data(){
        return{
            totalfields:[
                {key:'product_total',label:'Warenwert',formatter:(value)=>this.formatMoney(value),tdClass:'tdright'},
                {key:'shipping',label:'Versand',formatter:(value)=>this.formatMoney(value),tdClass:'tdright'},
                {key:'handling',label:'Mindermengenzuschlag',formatter:(value)=>this.formatMoney(value),tdClass:'tdright'},
                {key:'totaltax',label:'Mehrwertsteuer',formatter:(value)=>this.formatMoney(value),tdClass:'tdright'},
                {key:'grosstotal',label:'Gesamt',formatter:(value)=>this.formatMoney(value),tdClass:'tdright'}
            ],
            basketfields: [
                {key: 'product.name', label: 'Artikel'},
                {key: 'product.sku', label: 'Artikelnr'},
                {key: 'product.store_price', label: 'Preis',formatter:(value)=>this.formatMoney(value)},
                {key: 'qty', label: 'Menge'},
                {key:'ref',label:'Referenz'},
                {key:'sum',label:'Summe',formatter:(value)=>this.formatMoney(value)}
            ],
            adrfields:[
                {key:'salutation',label:'Anrede'},
                {key:'firstname',label:'Vorname'},
                {key:'lastname',label:'Nachname'},
                {key:'company',label:'Firma'},
                {key:'street',label:'Straße',formatter:'streetAddress'},
                {key:'city',label:'Ort',formatter:'cityAddress'},
                {key:'country_id',label:'Land',formatter:'countryName'},
                {key:'email',label:'E-Mail-Adresse'},
                {key:'telephone',label:'Telefon'},
                {key:'telefax',label:'Telefax'}
            ]
        }
    },
    methods: {
        formatMoney(value) {
            return helpers.formatMoney(value) + '€'
        },
        streetAddress(value,key,item){
            return  `${value} ${item.street_no}`
        },
        cityAddress(value,key,item){
            return `${item.postalcode} ${value}`
        },
        countryName(value){
            if(value==='DE'){
                return 'Deutschland'
            }
            return '';
        }
    },
    computed:{
        cartProducts(){
            return this.$store.getters['cart/orderedProducts']
        },
        cartTotals(){
            return this.$store.getters['cart/orderedTotals']
        },
        cartRef(){
          return this.$store.getters['cart/orderedBasketRef']
        },
        billingAdr(){
            return this.$store.getters['cart/billingAdr']
        },
        shippingAdr(){

            return this.$store.getters['cart/shippingAdr']
        },
        useShipping(){
            return this.$store.getters['cart/useShipping']
        },
       /* custBillingAdrFields(){
            if(this.billingAdr.custno.length>0){
                return this.adrfields.unshift( {key:'custno',label:'Kundennummer'})
            }
            else{
                return this.adrfields
            }
        }*/
    }
}
</script>

<style scoped>

</style>