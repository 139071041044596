import jsonApi from "@/service/jsonapiClient";

const productHotspots={
    namespaced:true,
    state:()=>({
        hotspots:[]
    }),
    mutations:{
        productHotspot(state, data) {
            const record = state.hotspots.find(p => p.id === data.id);
            if(!record){
                state.hotspots.push({
                    id: data.id,
                    hotspots:data.res
                })
            }
        }
    },
    actions:{
        productHotspot({commit,state},productId){
            if(typeof state.hotspots[productId]==='undefined') {
                return jsonApi.one('product', productId).all('hotspot').get({
                    include: 'drawing.machine',
                }).then(res => {
                    console.log(res.data)
                    commit('productHotspot', {res:res.data,id:productId})
                }).catch(err => {
                    console.log(err)
                })
            }
        }
    },
    getters:{
        productHotspot:(state)=>(id)=>{
            const record = state.hotspots.find(p => p.id === id);
            if(record){
                return record.hotspots
            }
            return []

        },
        productHotspotLoaded:(state)=>(id)=>{
            const record = state.hotspots.find(p => p.id === id);
            if(record){
                return true
            }
            return false
        }
    }
}

export default productHotspots