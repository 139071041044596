<template>
    <div>
    <b-table
        :items="itemsProvider"
        primary-key="id"
        :fields="fields"
        :busy.sync="isBusy"
        :id="'detTable'+product_id"
        no-provider-paging
        :per-page="perPage"
        :current-page="currentPage"
        small
        dark
        show-empty
        empty-text="keine Geräte gefunden"
        stacked="sm"
    >
        <template #table-busy>
            <div class="mt-5 text-center">
                <b-spinner class="mt-5"   label="Loading..."></b-spinner>
                <p>Lade Daten</p>
            </div>
        </template>
        <template #cell(lpos)="{item}">
            <span>{{item.drawing.list_num}}/{{item.posnum}}</span>
        </template>
        <template #cell(maction)="{item}">
            <b-button @click="showMachine(item.drawing.machine.id,{ilist:item.drawing.list_num,ipos:item.posnum})">Zur Zeichnung</b-button>
        </template>
    </b-table>
        <b-pagination
                v-show="productDetail.length>perPage"
                v-model="currentPage"
                :total-rows="productDetail.length"
                :per-page="perPage"
                :aria-controls="'detTable'+product_id"
                size="sm"
        ></b-pagination>
    </div>
</template>

<script>
export default {
    name: "product-detail",
    props:['product_id'],
    data(){
        return {
            perPage:3,
            currentPage:1,
            fields:[
                {key: 'drawing.machine.name', label: 'Gerät'},
                {key:'drawing.machine.manufacturerType',label:'Typ'},
                {key: 'drawing.machine.supplier.name', label: 'Hersteller'},
                {key:'lpos',label:'Liste/Position'},
                {key:'maction',label:''}
               /* {key: 'store_price', label: 'Preis netto',formatter:(value)=>this.formatMoney(value)+'€'},
                {key: 'gross_store_price', label: 'Preis brutto',formatter:(value)=>this.formatMoney(value)+'€'},
                {key:'available',label:'Verfügbarkeit'},
                {key:'qty',label:'Bestellen'},
                'show_details'*/
            ],
            isBusy: false
        }
    },
    methods:{
        itemsProvider(ctx,callback) {
            /* console.log('provider ctxpage',ctx.currentPage)
             console.log('provider loaded',this.searchLoaded)
             console.log('provider apipage',this.currentPage)*/
            this.isBusy = true
            if(this.productDetailLoaded){
                this.isBusy = false
                callback(this.productDetail)
            }
            else{
                this.$store.dispatch('productHotspots/productHotspot',this.product_id).then(()=>{
                    const items=this.productDetail
                    this.isBusy = false
                    callback(items)
                }).catch(()=>{
                    this.isBusy = false
                    callback([])
                })
            }
            return null
        },
        showMachine(id,oPos){
            this.$store.dispatch('explodedView/machineId',id).then(()=>{
                // this.$store.dispatch('explodedView/machine').then(()=>{
                this.$router.push({name:'exploded-view',params:oPos})//,params:{test:'Testpush'}
                //})

            })

        },
    },
    computed:{
        productDetail:function(){
           return this.$store.getters['productHotspots/productHotspot'](this.product_id)
        },
        productDetailLoaded:function(){
            return this.$store.getters['productHotspots/productHotspotLoaded'](this.product_id)
        }
    }
}
</script>

<style scoped>


</style>