<template>
   <!-- <div>
        <b-form-input v-model="msearch" @keyup="searchMachine" placeholder="Maschine"></b-form-input>
    </div>-->

    <b-nav-form @submit="searchProduct">
        <b-form-row>
            <b-col>
        <b-form-input size="sm" v-model="psearch" required class="" placeholder="Ersatzteil"></b-form-input>
            </b-col>
            <b-col>
        <b-form-select  v-model="supSelected" size="sm" class="form-control" :options="suppliers" right></b-form-select>
            </b-col>
            <b-col cols="2">
        <b-button  size="sm" class="" type="submit">Suche</b-button>
            </b-col>
        </b-form-row>
    </b-nav-form>

</template>

<script>
export default {
    name: "prod-search-bar",
    data(){
        return {
            psearch:'',
            supSelected:null,

        }
    },
    methods:{
        searchProduct: function (e) {
            e.preventDefault()
            if(this.psearch||this.supSelected) {
                this.$store.dispatch('search/searchQuery', {sterm: this.psearch, sid: this.supSelected,stype:'product'})

                if (this.$router.currentRoute.path !== '/psearch') {
                    this.$router.push('/psearch')
                }
            }

        }
    },
    computed:{
        suppliers(){
            const  supOptions=[{
                text:'Hersteller',
                value:null
            }]
            if(this.$store.getters['inits/suppliers'].length>0){
                let suppliers=  this.$store.getters['inits/suppliers']
               suppliers.forEach((supplier)=>{
                    supOptions.push({text:supplier.name,value:supplier.id})
                })

            }
            return supOptions;

        }
    }
}
</script>

<style scoped>
.form-control{
    width:100%;
}
</style>