<template>
    <b-container >
        <h4 class="text-center pt-3">Ersatzteilsuche</h4>
        <b-navbar>
            <prod-search-bar></prod-search-bar>
        </b-navbar>

        <div class="mt-2 text-center" v-if="searchTerm.length>0||searchSupplier.length>0">

            <span>Suchergebnisse zu </span>
            <span v-if="searchTerm.length>0">'{{searchTerm}}'</span>
            <span v-if="searchTerm.length>0&&searchSupplier.length>0"> und </span>
            <span v-if="searchSupplier.length>0"> Hersteller {{searchSupplier}}</span>
        </div>
        <div v-if="loadState===0" class="mt-5 text-center">
            <b-icon class="h2 mt-5" icon="exclamation-circle-fill" variant="info"></b-icon>
            <p>Sie haben noch keine Suche gestartet.</p>
        </div>
        <div v-else-if="loadState===1" class="mt-5 text-center">
            <b-spinner class="mt-5"   label="Loading..."></b-spinner>
            <p>Suche Ersatzteile</p>
        </div>
        <div v-else-if="loadState===3" class="mt-5 text-center">
            <b-icon class="h2 mt-5" icon="exclamation-circle-fill" variant="info"></b-icon>
            <p>Es wurde kein Ersatzteil gefunden</p>
        </div>

        <div v-show="loadState===2"> <!-- v-if="(searchTerm.length>0||searchSupplier.length>0)"-->
            <b-table
                    :items="itemsProvider"
                    :fields="fields"
                    primary-key="id"
                    id="presult-table"
                    :current-page="currentPage"
                    :per-page="0"
                    stacked="sm"
            >
                <template #cell(qty)="data" >
                    <b-input-group size="sm">
                        <b-form-input
                                :value="cartQty(data.item)>0?cartQty(data.item):1"
                                :ref="'pqty'+data.index"
                                type="number"
                                min="0"
                                class="inwidth"
                        >
                        </b-form-input>

                        <b-input-group-append>
                            <b-button  @click="addToCart(data)">
                                <b-icon    icon="cart" aria-hidden="true"></b-icon>
                            </b-button>
                        </b-input-group-append>
                    </b-input-group>
                    <!--<b-row class="m-0">
                        <b-col class="text-right  m-0">
                            <b-input :value="cartQty(data.item)" :ref="'pqty'+data.index" type="number" min="0"></b-input>
                        </b-col>
                        <b-col class="text-left m-0">
                            <b-button @click="addToCart(data)">
                                <b-icon    icon="cart" aria-hidden="true"></b-icon>
                            </b-button>
                        </b-col>
                    </b-row>-->
                </template>
                <template #cell(available)="data">
                    <b-row class="m-0" >
                        <b-col class="m-0" :style="availableInfo(data).style">
                            <b-icon icon="truck" aria-hidden="true"></b-icon>&nbsp;{{availableInfo(data).txt}}
                        </b-col>
                    </b-row>
                </template>

                <template #cell(show_details)="data">

                  <b-form-checkbox  v-model="data.detailsShowing" @change="data.toggleDetails">
                       Zeige passende Geräte
                    </b-form-checkbox>

                </template>
                <template #row-details="data">
                  <product-detail :product_id="data.item.id"></product-detail>
                   <!-- <p>{{data}}</p>-->
                </template>
            </b-table>
            <b-pagination
                    v-model="currentPage"
                    :total-rows="rows"
                    :per-page="perPage"
                    aria-controls="result-table"
            >
            </b-pagination>
        </div>
    </b-container>
</template>

<script>
    import ProdSearchBar from "@/components/ProdSearchBar";
    import helpers from "@/service/helpers";
    import ProductDetail from "@/components/ProductDetail";
    export default {
        name: "ProductSearch",
        components: {ProductDetail, ProdSearchBar},
        data(){
            return {
                fields:[
                    {key: 'name', label: 'Ersatzteil'},
                    {key: 'sku', label: 'Artikelnr'},
                    {key:'supplier.name',label:'Hersteller'},
                    {key: 'store_price', label: 'Preis netto',formatter:(value)=>this.formatMoney(value)+'€',tdClass:'text-right'},
                    {key: 'gross_store_price', label: 'Preis brutto',formatter:(value)=>this.formatMoney(value)+'€',tdClass:'text-right'},
                    {key:'available',label:'Verfügbarkeit'},
                    {key:'qty',label:'Bestellen'},
                    {key:'show_details',label:''}
                ]
            }
        },
        methods:{
            formatMoney:helpers.formatMoney,
            availableInfo:helpers.availableInfo,
            itemsProvider(ctx,callback) {
                /* console.log('provider ctxpage',ctx.currentPage)
                 console.log('provider loaded',this.searchLoaded)
                 console.log('provider apipage',this.currentPage)*/
                if(this.searchLoaded){
                    callback(this.productResults.data)
                }
                else{
                    this.$store.dispatch('search/searchProduct').then(()=>{
                        const items=this.productResults.data
                        callback(items)
                    }).catch(()=>{
                        callback([])
                    })
                }
                return null
            },
            addToCart(data){
                const qty=this.$refs['pqty'+data.index].$el._value
                const product=data.item
                if(window.etsAddToCart){
                    let etsProduct=helpers.cleanSource(product)
                    etsProduct.qty=qty
                    window.etsAddToCart(helpers.cleanSource(etsProduct))
                }
                //console.log('val',data.item.product)
                this.$store.dispatch('cart/addProduct',{product:product,qty:qty})
            }
        },
        computed:{
            productResults(){
                return this.$store.getters['search/productResults'];
            },
            searchLoaded:function(){
                let query = this.$store.getters['search/psearchQuery']
                return query.resultLoaded
            },
            searchTerm:function(){
                return  this.$store.getters['search/psearchQuery'].sterm
            },
            searchSupplier:function(){
                return  this.$store.getters['search/psearchQuery'].sname
            },
            loadState(){
                return this.$store.getters['search/ploadState']
            },
            rows(){
                if(this.$store.getters['search/productResults'].meta){
                    return this.$store.getters['search/productResults'].meta.pagination.total
                }

                return 0
            },
            perPage(){
                if(this.$store.getters['search/productResults'].meta) {
                    return this.$store.getters['search/productResults'].meta.pagination.per_page
                }
                return 0
            },
            currentPage:{
                get: function(){
                    /* let page=1
                     if(this.$store.getters['search/machineResults'].meta) {
                         page=this.$store.getters['search/machineResults'].meta.pagination.current_page
                     }
                     console.log('computed getPage',page)
                     return page*/
                    return this.$store.getters['search/psearchQuery'].page
                },
                set:function(newVal){
                    this.$store.dispatch('search/currentPage',{page:newVal,stype:'product'})
                }
            },
            watchQuery: function () {
                let query = this.$store.getters['search/psearchQuery']
                return query.sterm + " " + query.sname
            },
            cartQty(){
                return this.$store.getters['cart/cartProductQty']
            }
        },
        watch:{
            watchQuery(newVal,oldVal){
                if(newVal!==oldVal){
                    /* console.log('wsatch new',newVal)
                     console.log('watch old',oldVal)*/
                    // this.resetPage()
                    this.$root.$emit('bv::refresh::table', 'presult-table')
                }
            }
        },
    }
</script>

<style scoped>

</style>