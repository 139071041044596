const helpers={
    formatMoney(number, decPlaces, decSep, thouSep) {
        decPlaces = isNaN(decPlaces = Math.abs(decPlaces)) ? 2 : decPlaces,
        decSep = typeof decSep === "undefined" ? "," : decSep;
        thouSep = typeof thouSep === "undefined" ? "." : thouSep;
        let sign = number < 0 ? "-" : "";
        let i = String(parseInt(number = Math.abs(Number(number) || 0).toFixed(decPlaces)));

        let j = i.length;
         j = (j > 3) ? j % 3 : 0;

        return sign +
            (j ? i.substr(0, j) + thouSep : "") +
            i.substr(j).replace(/(\decSep{3})(?=\decSep)/g, "$1" + thouSep) +
            (decPlaces ? decSep + Math.abs(number - i).toFixed(decPlaces).slice(2) : "");
    },
    financial(x){
        return Number.parseFloat(x).toFixed(2);
    },
    availableInfo(data){
        const product=data.item
        const ret={
            txt:'',
            style:''

        }
        switch (product.available) {
            case 0:
                ret.txt = "Nicht mehr Lieferbar!";
                ret.style = "color:red;";
                break;
            case 2:
                ret.txt = "befriedigende Verfügbarkeit";
                ret.style = "color:green;"
                break;
            case 3:
                ret.txt = "kritische Verfügbarkeit";
                ret.style = "color:orange";
                break;
            case 4:
                ret.txt = "Auf Anfrage!";
                ret.style = "color:red";
                break;
            default:
                ret.txt = "(sehr) gute Verfügbarkeit";
                ret.style = "color:green";
        }
        return ret;
    },
    cleanSource (source) {
    // using native JSON functions removes reactivity
    // so we can clone an object without mutating the original source
    return JSON.parse(JSON.stringify(source));
}


}


export default helpers