<template>
    <div id="pcard" :style="mystyle" >
        <b-card
                class="mb-5"
                no-body
        >
            <b-card-header>
                <b-button-close v-show="orderVisible" @click="hideCard"></b-button-close>
                Position {{hotspot.posnum}}
            </b-card-header>
            <b-card-body>
                <b-card-title title-tag="h5">
                    {{ product.name}}
                </b-card-title>

                <b-card-text>
                    <b-row  >
                        <b-col sm="7" class="text-sm-left"><b>Artikelnummer</b></b-col>
                    </b-row>
                    <b-row class="mb-2">
                        <b-col sm="8" class="text-right">{{ product.sku }}</b-col>
                    </b-row>

                    <b-row>
                        <b-col sm="7" class="text-sm-left"><b>Preis brutto</b></b-col>
                    </b-row>
                    <b-row class="mb-2">
                        <b-col sm="8" class="text-right">{{ formatMoney(product.gross_store_price )}}€</b-col>
                    </b-row>

                    <b-row >
                        <b-col sm="7" class="text-sm-left"><b>Preis netto</b></b-col>
                    </b-row>
                    <b-row>
                        <b-col sm="8" class="text-right">{{formatMoney(product.store_price) }}€</b-col>
                    </b-row>

                    <div v-show="orderVisible">
                        <b-row>
                            <b-col  class="">
                                <b-form-group
                                        label="Bestellen"
                                        :label-for="'p'+product.id"
                                        label-cols-sm="6"
                                        label-align-sm="left"
                                        label-class=""
                                        class="mb-0 mt-2"
                                >
                                    <template slot="label" >
                                        <b-icon icon="cart" aria-hidden="true"></b-icon>&nbsp;<b>Bestellen</b>
                                    </template>
                                    <b-input-group
                                            size="sm"
                                    >
                                        <b-form-input
                                            :value="cartQty(product)>0?cartQty(product):1"
                                            ref="pqty"
                                            type="number"
                                            min="0"
                                            class="inwidth"
                                            :id="'p'+product.id"
                                            size="sm"
                                        >
                                        </b-form-input>
                                        <b-input-group-append>
                                            <b-button size="sm" @click="addToCart">
                                                <b-icon    icon="cart" aria-hidden="true"></b-icon>
                                            </b-button>
                                        </b-input-group-append>
                                    </b-input-group>
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <b-row class="mb-2" v-show="orderVisible">
                            <b-col :style="availableInfo().style">
                                <b-icon icon="truck" aria-hidden="true"></b-icon>&nbsp;{{availableInfo().txt}}
                            </b-col>
                        </b-row>

                        <b-row >
                            <b-col>
                                <span>Lieferbar in {{product.delivery_days}}&nbsp;</span>
                                <span v-if="product.delivery_days>1">Tagen</span>
                                <span v-else>Tag</span>
                            </b-col>
                        </b-row>
                    </div>
                </b-card-text>
            </b-card-body>

        </b-card>
    </div >
</template>

<script>
    import helpers from "../service/helpers";
export default {
    name: "order-card",
    props:[],
    data(){
        return{

        }
    },
    methods:{
        formatMoney:helpers.formatMoney,
        hideCard(){
            this.$store.dispatch('explodedView/hotspotVisibleInfo',false)
            this.$store.dispatch('explodedView/hotspotVisibleOrder',false)
        },
        availableInfo(){
            const ret={
                txt:'',
                style:''

            }
            switch (this.product.available) {
                case 0:
                    ret.txt = "Nicht mehr Lieferbar!";
                    ret.style = "color:red;";
                    break;
                case 2:
                    ret.txt = "befriedigende Verfügbarkeit";
                    ret.style = "color:green;"
                    break;
                case 3:
                    ret.txt = "kritische Verfügbarkeit";
                    ret.style = "color:orange";
                    break;
                case 4:
                    ret.txt = "Auf Anfrage!";
                    ret.style = "color:red";
                    break;
                default:
                    ret.txt = "(sehr) gute Verfügbarkeit";
                    ret.style = "color:green";
            }
            return ret;
        },
        addToCart(){
            if(this.product.id) {
                //console.log('order',this.$refs.pqty.$el._value)
                if(window.etsAddToCart){
                    let etsProduct=helpers.cleanSource(this.product)
                    etsProduct.qty=this.$refs.pqty.$el._value
                    window.etsAddToCart(helpers.cleanSource(etsProduct))
                }
                this.$store.dispatch('cart/addProduct',{product:this.product,qty:this.$refs.pqty.$el._value})
            }
        }
    },
    computed:{
        machine(){
            return this.$store.getters['explodedView/machine']
        },
        hotspot(){
            return this.$store.getters['explodedView/hotspot']
        },
        product(){
           // const machine= this.$store.getters['machine/loadedMachine']
            if(Object.prototype.hasOwnProperty.call(this.hotspot,'product')){
                return this.hotspot.product
            }
           return {}
           // return machine.drawing[this.drawingIndex].hotspot[this.spotIndex].product
        },
        mystyle(){
            const top=this.$store.getters['explodedView/hotspotTop']
            const left=this.$store.getters['explodedView/hotspotLeft']
            const visisble=this.$store.getters['explodedView/hotspotVisibleInfo']
           /* console.log('pcard.offsetWidth:'+document.getElementById('pcard').offsetWidth)
            console.log('mapArea.offsetLeft:'+document.getElementById('mapArea').offsetLeft)
            console.log('hotspot left:'+left);
            console.log('mapStage.offsetWidth:'+document.getElementById('mapStage').offsetWidth)*/
          let leftcorrect=0;
          let topcorrect=0;
          if(document.getElementById('pcard')) {
            const pcardWidth = document.getElementById('pcard').offsetWidth
            const pcardHeight=document.getElementById('pcard').offsetHeight

            if (pcardWidth + document.getElementById('mapArea').offsetLeft + parseInt(left) > document.getElementById('mapStage').offsetWidth) {
              leftcorrect = pcardWidth
            }
            if(pcardHeight+document.getElementById('mapArea').offsetTop + parseInt(top) > document.getElementById('mapStage').offsetHeight){
              topcorrect=pcardHeight

            }

          }
         // topcorrect=0
            return {
                top:topcorrect>0?(parseInt(top)-topcorrect)+'px':top,
                left:leftcorrect>0?(parseInt(left)-leftcorrect)+'px':left,
                visibility:visisble?'visible':'hidden'

            }
        },
        orderVisible(){
            return this.$store.getters['explodedView/hotspotVisibleOrder']
        },
        cartQty(){
            return this.$store.getters['cart/cartProductQty']
        }

    }
}
</script>

<style >
#pcard{
    position: absolute;
    display:flex;
   /* width:max-content;*/
}

.card-text{
    min-width: 220px;
}

.card{
   /* min-width:400px*/
}
</style>