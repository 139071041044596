<template>
  <b-nav-form @submit="searchSubmit" >
    <b-form-row>
      <b-col lg="3" md="3" cols="3">
        <b-form-select class="form-control" size="sm" v-model="searchSelected" :options="searchOn"></b-form-select>
      </b-col>
      <b-col lg="5" md="4" cols="4">
        <b-form-input size="sm" v-model="csearch" class="" :required="setInputRequired()" :placeholder="setDefaultInputTxt()" ></b-form-input>
      </b-col>
      <b-col lg="3" md="3" cols="3">
        <b-form-select  v-model="supSelected" size="sm" class="form-control" :options="suppliers"></b-form-select>
      </b-col>
      <b-col lg="1" md="2" cols="2" >
        <b-button  size="sm" class="" type="submit" title="suche">  <b-icon icon="search"></b-icon></b-button>
      </b-col>
    </b-form-row>
  </b-nav-form>
</template>

<script>
export default {
  name: "CombinedSearchBar",
  data(){
    return {
      csearch:'',
      supSelected:null,
      searchSelected:'machine',
      searchOn:[
        {value:'product',text:'Suche  Ersatzteil'},
        {value:'machine',text:'Suche  Gerät'}
      ]

    }
  },
  methods:{
    setDefaultInputTxt:function(){
      if(this.searchSelected==='product'){
        return 'Ersatzteil'
      }
      else{
        return 'Gerät'
      }
    },
    setInputRequired:function(){
      if(this.searchSelected==='product'){
        return true
      }
      else{
        return false
      }
    },
    searchSubmit: function (e) {
      e.preventDefault()
      if(this.csearch||this.supSelected) {
        this.$store.dispatch('search/searchQuery', {sterm: this.csearch, sid: this.supSelected,stype:this.searchSelected})
        //this.$root.$emit('bv::toggle::collapse', 'nav-collapse')
        if(this.searchSelected==='product'){
          if (this.$router.currentRoute.path !== '/psearch') {
            this.$router.push('/psearch')
          }
        }
        else {
          if (this.$router.currentRoute.path !== '/search') {
            this.$router.push('/search')
          }
        }
      }

    }
  },
  computed:{
    suppliers(){
      const  supOptions=[{
        text:'Hersteller',
        value:null
      }]
      if(this.$store.getters['inits/suppliers'].length>0){
        let suppliers=  this.$store.getters['inits/suppliers']
        suppliers.forEach((supplier)=>{
          supOptions.push({text:supplier.name,value:supplier.id})
        })

      }
      return supOptions;

    }
  }
}
</script>

<style scoped>
.form-control{
  width:100%;
}
</style>