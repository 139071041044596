<template>
    <div  id="invoice-button-container" ref="invoice">
       <b-row>
           <b-col>
               <b-button id="invoice-button" @click="dispatchOrder" pill>Kauf auf Rechnung</b-button>
           </b-col>
       </b-row>
        <b-row>
            <b-col class="text-center">
                <span class="invoice-powered-by">Abgewickelt durch <b>{{company}}</b></span>
            </b-col>
        </b-row>
    </div>
</template>

<script>
export default {
    name: "invoice-button",
    methods:{
        dispatchOrder:function (){
          this.$store.dispatch('cart/createOrder',{invoicing:1}).then(()=>{
                if(this.$store.getters['cart/createError']){
                  let strErr='';
                  let arrErr=this.$store.getters['cart/createErrors'];
                  if(arrErr.length>0){
                    arrErr.forEach(str=>strErr+=str+'<br>')
                  }
                  this.showCancel(strErr)
                }
                else {
                  this.$store.dispatch('cart/captureOrder', {orderID: this.$store.getters['cart/getPpOrderId']}).then((res) => {
                    if (res.committed) {
                      this.$router.push('/basket/order-confirmation')
                    }
                    //alert('captured')
                  }).catch(err => {
                    console.log(err)
                    this.showCancel('Die Transaktion konnte leider nicht bearbeitet werden. ' +
                        'Ihre Bestellung wurde nicht ausgeführt')
                  })
                }
          })
      },
      showCancel(msg){
        this.boxOne = ''
        const msgHtml=this.$createElement('div',{domProps:{innerHTML:msg+'<br>Ihre Bestellung wurde nicht ausgeführt'}})
        this.$bvModal.msgBoxOk([msgHtml],{
          title:'Fehler',
          okVariant:'danger',
          size: 'sm',
          buttonSize: 'sm',
          headerClass: 'p-2 border-bottom-0',
          footerClass: 'p-2 border-top-0',
          centered: true
        })
            .then(value => {
              this.boxOne = value
            })
      }

    },
    computed:{
        company(){
            return this.$store.getters['inits/storeConfig'].company
        },
    }
}
</script>

<style scoped>
    @media screen and (max-width: 400px) {
        #invoice-button-container {
            width: 100%;
            margin:auto
        }
    }

    /* Media query for desktop viewport */
    @media screen and (min-width: 400px) {
        #invoice-button-container {
            width: 250px;
            margin:auto
        }
    }

    #invoice-button {
        min-width: 250px;
        max-width: 300px;
    }
    .invoice-powered-by {
        display: inline-block;
        vertical-align: middle;
        height: 16px;
        line-height: 16px;
        font-size: 11px;
        font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
        font-size: 11px;
        font-weight: normal;
        font-style: italic;
        font-stretch: normal;
        color: #7b8388;
    }

</style>