import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersist from 'vuex-persist';
import localForage from 'localforage';
import inits from './modules/inits'
import search from "./modules/search";
import explodedView from "./modules/explodedView";
import cart from "./modules/cart"
//import productDetails from "./modules/productDetails"
import productHotspots from "./modules/productHotspots"
//import router from "@/router";

Vue.use(Vuex)

const vuexStorage = new VuexPersist({
  key: 'sourcelink',
  storage: localForage,
  // You can change this explicitly use
  // either window.localStorage  or window.sessionStorage
  // However we are going to make use of localForage
})

const shopStore= new Vuex.Store({
  plugins: [vuexStorage.plugin],
  modules:{
    inits,
    search,
    explodedView,
    cart,
  //  productDetails,
    productHotspots
  }
})

export default shopStore
