<template>
    <div class="layout">
        <top-header ref="navBar"></top-header>
        <div>
            <router-view></router-view>
        </div>
    </div>
</template>

<script>
    import TopHeader from "@/components/TopHeader";
    export default {
        name: "Layout",
        components:{
            "top-header":TopHeader
        }
    }
</script>

<style scoped>
    .layout{
        width:100%
    }
</style>