//import '@babel/polyfill'
import 'document-register-element/build/document-register-element'
import 'mutationobserver-shim'
import Vue from 'vue'
import store from './store'
import './plugins/bootstrap-vue'
import router from './router'
//import './plugins/vue-touch-events'
import App from './App'
import vueCustomElement from 'vue-custom-element'
Vue.config.productionTip = false
Vue.use(vueCustomElement)
App.store=store
App.router=router

/*const options = {
    shadow: false,
    beforeCreateVueInstance(root){

        const rootNode = root.el.getRootNode();

        if(rootNode instanceof ShadowRoot){

            root.shadowRoot = rootNode

        }else{

            root.shadowRoot = document.head;
        }

        return root;

    }
}
Vue.customElement('et-store',App,options)*/

Vue.customElement('et-store',App)

/*new Vue({
  router,
  store,
  render: h => h(App),

  created() {

    },
  mounted() {
    //this.$router.replace('/')

  }
}).$mount('#app')*/

/*new Vue({
  el: '#app',
  router,
  template: '<App/>',
  components: { App },
  mounted() {
    this.$router.replace('/')
  }
})*/
