import jsonApi from "../../service/jsonapiClient";

const inits={
    namespaced:true,
    state:()=>({
        storeConfig:{
            store_shipping:0,
            store_handling:0,
            store_shipping_threshhold:0,
            store_handling_threshhold:0,
            tax:0,
            paypal_client_id:process.env.VUE_APP_PAYPAL_SANDBOX_CLIENTID,
            paypal_mode:'sandbox',
            invoicing:0
        },
        suppliers:[],
        /**
         * 0 loading
         * 1 authorized - waiting for suppliers
         * 2 unauthorized
         * 3 authorized &suppliers loaded
         * 4 error auth
         * 5 error supplier
         */
        stateAuth:0,
        logoUrl:'https://ets.serviceparts24.com/img/acme.svg',
        agbUrl:'https://ets/serviceparts24.com/acmeagb.html',
        strWelcome:'',
        noCheckout:false
    }),
    mutations:{
        storeConfig(state,data){
            state.storeConfig=data
        },
        suppliers(state,sups){
            state.suppliers=sups;
        },
        stateAuth(state,authId){
            state.stateAuth=authId
        },
        logoUrl(state,url){
            state.logoUrl=url
        },
        strWelcome(state,str){
          state.strWelcome=str
        },
        agbUrl(state,url){
            state.agbUrl=url
        },
        noCheckout(state,nocheckout){
            if(nocheckout===1){
                state.noCheckout=true
            }
        }
    },
    actions:{
        suppliers({commit,state}){
            if(state.stateAuth===1) {
                return jsonApi.all('supplier').get({scope: 'machines'}).then(res => {
                    let suppliers = res.data
                    commit('suppliers', suppliers)
                    commit('stateAuth',3)
                }).catch(error =>{
                    console.log(error)
                    commit('stateAuth',5)
                })
            }
        },
        storeConfig({commit},oProps){
            jsonApi.headers['hs-api-auth-token'] = 'hsbasic '+oProps.token;
            return jsonApi.findAll('storeConfig').then(res=>{
                commit('storeConfig',res.data)
                if(res.data.store_authorized){
                    commit("stateAuth",1)
                    commit("logoUrl",oProps.logoUrl)
                    commit("agbUrl",oProps.agbUrl)
                    commit("strWelcome",oProps.strWelcome)
                    commit("noCheckout",oProps.noccheckout)
                }
                else{
                    commit("stateAuth",2)
                }
                //return res.data.authorized
            }).catch(error=>{
                console.log('storeError',error)
                commit('storeConfig',{store_authorized:false})
                if(error.status===401){
                    commit("stateAuth",2)
                }
                else{
                    commit("stateAuth",4)
                }

                //return false;
                //router.push('/unauthorized')
            })
        }
    },
    getters:{
        suppliers:state=>{
            return state.suppliers
        },
        storeConfig:state=>{
            return state.storeConfig;
        },
        authorized:state=>{
            return state.storeConfig.store_authorized
        },
        stateAuth:state=>{
            return state.stateAuth
        },
        logoUrl:state=>{
            return state.logoUrl
        },
        agbUrl:state=>{
            return state.agbUrl
        },
        strWelcome:state=>{
          return state.strWelcome
        },
        shipping_gross:state=>{
            return state.storeConfig.store_shipping*(1+state.storeConfig.tax/100)
        },
        handling_gross:state=>{
            return state.storeConfig.store_handling*(1+state.storeConfig.tax/100)
        },
        paypal_client_id: state=>{
            return state.storeConfig.paypal_client_id
        },
        noCheckout:state=>{
            return state.noCheckout
        }
    }
}

export default inits