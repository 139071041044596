import Vue from 'vue'

//import {BootstrapVue,IconsPlugin} from 'bootstrap-vue'
import {
    IconsPlugin,
    LayoutPlugin,
    CardPlugin,
    TablePlugin,
    FormPlugin,
    FormInputPlugin,
    FormSelectPlugin,
    FormCheckboxPlugin,
    FormGroupPlugin,
    NavbarPlugin,
    ButtonPlugin,
    SpinnerPlugin,
    ButtonToolbarPlugin,
    ButtonGroupPlugin,
    TabsPlugin,
    ModalPlugin,
    OverlayPlugin,
    LinkPlugin,
    PaginationPlugin,
    InputGroupPlugin} from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

Vue.use(LayoutPlugin)
Vue.use(CardPlugin)
Vue.use(TablePlugin)
Vue.use(FormPlugin)
Vue.use(FormInputPlugin)
Vue.use(FormSelectPlugin)
Vue.use(FormCheckboxPlugin)
Vue.use(FormGroupPlugin)
Vue.use(NavbarPlugin)
Vue.use(ButtonPlugin)
Vue.use(SpinnerPlugin)
Vue.use(ButtonToolbarPlugin)
Vue.use(ButtonGroupPlugin)
Vue.use(TabsPlugin)
Vue.use(PaginationPlugin )
Vue.use(ModalPlugin)
Vue.use(OverlayPlugin)
Vue.use(LinkPlugin)
Vue.use(IconsPlugin)
Vue.use(InputGroupPlugin)
//Vue.use(BootstrapVue)