<template>

    <b-container >
        <h4 class="text-center pt-3">Gerätesuche</h4>
<b-navbar>
    <masch-search-bar></masch-search-bar>
</b-navbar>

        <div class="mt-2 text-center" v-if="searchTerm.length>0||searchSupplier.length>0">
            <span>Suchergebnisse zu </span>
            <span v-if="searchTerm.length>0">'{{searchTerm}}'</span>
            <span v-if="searchTerm.length>0&&searchSupplier.length>0"> und </span>
            <span v-if="searchSupplier.length>0"> Hersteller {{searchSupplier}}</span>
        </div>
        <div v-if="loadState===0" class="mt-5 text-center">
            <b-icon class="h2 mt-5" icon="exclamation-circle-fill" variant="info"></b-icon>
            <p>Sie haben noch keine Suche gestartet.</p>
        </div>
        <div v-else-if="loadState===1" class="mt-5 text-center">
            <b-spinner class="mt-5"   label="Loading..."></b-spinner>
            <p>Suche Geräte</p>
        </div>
        <div v-else-if="loadState===3" class="mt-5 text-center">
            <b-icon class="h2 mt-5" icon="exclamation-circle-fill" variant="info"></b-icon>
            <p>Es wurde kein Gerät gefunden</p>
        </div>

        <div v-show="loadState===2"> <!-- v-if="(searchTerm.length>0||searchSupplier.length>0)"-->
        <b-table
                :items="itemsProvider"
                :fields="fields"
                primary-key="id"
                id="result-table"
                :current-page="currentPage"
                :per-page="0"
                stacked="sm"

        >
            <template #cell(supplier)="data">
              {{ data.value.name}}
            </template>
            <template #cell(id)="data">
                <b-button @click="showMachine(data.value)">Zur Zeichnung</b-button>

            </template>

        </b-table>
        <b-pagination
                v-model="currentPage"
                :total-rows="rows"
                :per-page="perPage"
                aria-controls="result-table"
        >
        </b-pagination>
        </div>
    </b-container>
</template>

<script>

    import MaschSearchBar from "@/components/MaschSearchBar";
    export default {
        name: "MachineSearch",
        components: {MaschSearchBar},
        data(){
            return {
                fields:[
                    {key:'name',label:'Maschine'},
                    {key:'manufacturerType',label:'Typ'},
                    {key:'supplier',label:'Hersteller'},
                    {key:'id',label:'',tdClass:'text-center'}
                ],
              //  perPage: 2,
                //totalRows: 5,
                //pageOptions: [5, 10, 22],
                //currentPage: this.apiCurrentPage,
            }
        },
        methods:{
            itemsProvider(ctx,callback) {
               /* console.log('provider ctxpage',ctx.currentPage)
                console.log('provider loaded',this.searchLoaded)
                console.log('provider apipage',this.currentPage)*/
                if(this.searchLoaded){
                        callback(this.machineResults.data)
                }
                else{
                this.$store.dispatch('search/searchMachine').then(()=>{
                    const items=this.machineResults.data
                    callback(items)
                }).catch(()=>{
                    callback([])
                })
                }
                return null
            },
            showMachine(id){
                this.$store.dispatch('explodedView/machineId',id).then(()=>{
                   // this.$store.dispatch('explodedView/machine').then(()=>{
                        this.$router.push('/exploded-view')
                    //})

                })

            },
            resetPage(){
                this.currentPage=1
            }
        },
        computed:{
            machineResults(){
                return this.$store.getters['search/machineResults'];
            },
            watchQuery: function () {
                let query = this.$store.getters['search/searchQuery']
                return query.sterm + " " + query.sname
            },
            searchLoaded:function(){
                let query = this.$store.getters['search/searchQuery']
                return query.resultLoaded
            },
            searchTerm:function(){
              return  this.$store.getters['search/searchQuery'].sterm
            },
            searchSupplier:function(){
                return  this.$store.getters['search/searchQuery'].sname
            },
            rows(){
                if(this.$store.getters['search/machineResults'].meta){
                    return this.$store.getters['search/machineResults'].meta.pagination.total
                }

                return 0
            },
            perPage(){
                if(this.$store.getters['search/machineResults'].meta) {
                    return this.$store.getters['search/machineResults'].meta.pagination.per_page
                }
                return 0
            },
            loadState(){
                return this.$store.getters['search/loadState']
            },
            currentPage:{
                get: function(){
                   /* let page=1
                    if(this.$store.getters['search/machineResults'].meta) {
                        page=this.$store.getters['search/machineResults'].meta.pagination.current_page
                    }
                    console.log('computed getPage',page)
                    return page*/
                    return this.$store.getters['search/searchQuery'].page
                },
                set:function(newVal){
                    this.$store.dispatch('search/currentPage',{page:newVal})
                    console.log('computed setPage',newVal)
                }
            }
        },
        watch:{
            watchQuery(newVal,oldVal){
                if(newVal!==oldVal){
                   /* console.log('wsatch new',newVal)
                    console.log('watch old',oldVal)*/
                   // this.resetPage()
                    this.$root.$emit('bv::refresh::table', 'result-table')
                }
            }
        },

    }
</script>

<style scoped>

</style>