<template>
    <b-container>
        <b-row class="home text-center" align-v="center">
           <b-col>
               <b-row>
                   <b-col>
                        <img :alt="company" :src="logoUrl" style="width: 50%">
                   </b-col>
               </b-row>
               <b-row >
                   <b-col v-html="welcome">
                   </b-col>
               </b-row>
           </b-col>
        </b-row>
    </b-container>
</template>

<script>


export default {
    name: 'Home',
    components: {

        },
    props: {

    },
    computed:{
        welcome(){
            if(this.$store.getters['inits/storeConfig']){
                if(this.$store.getters['inits/strWelcome']){
                    return this.$store.getters['inits/strWelcome']
                }
                else{
                    return '<h1>Willkommen im Ersatzteilshop der Firma '+this.$store.getters['inits/storeConfig'].company+'</h1>'
               }
            }
            else{
                return "<h1>..lade</h1>"
            }

        },
        company(){
            return this.$store.getters['inits/storeConfig'].company
        },
        logoUrl(){
            return this.$store.getters['inits/logoUrl']
        }
    }
}
</script>

<style scoped>
    .home{
        height:70vh
    }
</style>
