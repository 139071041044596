<template>
  <div id="app"  class="main-wrapper">
      <div v-if="stateAuth===0" class="mt-5 m-auto">
          <b-spinner class="mt-5 m-auto"   label=""></b-spinner>
          <p class="text-center">Ersatzteilshop wird initialisiert</p>
      </div>

      <div v-else-if="stateAuth===1" class="mt-5 m-auto">
          <b-spinner class="mt-5 m-auto"   label="Loading..."></b-spinner>
          <p class="text-center">lade Hersteller</p>
      </div>

      <div v-else-if="stateAuth===2" class="mt-5 m-auto">
          <b-icon class="h2 mt-5 m-auto" icon="exclamation-circle-fill" variant="danger"></b-icon>
          <p class="text-center">Ersatzteilshop konnte nicht authorisiert werden</p>
      </div>

      <router-view v-else-if="stateAuth===3"></router-view>

      <div v-else-if="stateAuth===4" class="mt-5 m-auto">
          <b-icon class="h2 mt-5 m-auto" icon="exclamation-circle-fill" variant="danger"></b-icon>
          <p class="text-center">Fehler bei der Initialisierung</p>
      </div>

      <div v-else-if="stateAuth===5" class="mt-5 text-center">
          <b-icon class="h2 mt-5 m-auto" icon="exclamation-circle-fill" variant="danger"></b-icon>
          <p class="text-center">Fehler beim Laden der Hersteller</p>
      </div>

      <div v-else class="mt-5 text-center">
          <b-icon class="h2 mt-5 m-auto" icon="exclamation-circle-fill" variant="danger"></b-icon>
          <p class="text-center">Fehler</p>
      </div>

  </div>
</template>
<script>

    export default{
        name:'App',
        props:["token","logourl","agburl",'strwelcome','nocheckout','showmachineid'],
        computed:{
            stateAuth(){
                return this.$store.getters['inits/stateAuth']
            },
            storeConfig(){
                return this.$store.getters['inits/storeConfig']
            },
            paypal_client_id(){
                return this.$store.getters['inits/paypal_client_id']
            },
            loadMachineId(){
              let machineid=false
              if((this.showmachineid===undefined)||parseInt(this.showmachineid)===0){
                const urlParams=new URLSearchParams(window.location.search)
                machineid=urlParams.get('showmachineid')
                if(machineid===null||parseInt(machineid)===0){
                  machineid=false
                }

              }
              else{
                machineid=this.showmachineid
              }
              return machineid
            }

        },
        mounted() {

        },
        created() {
           // const token=document.getElementById('app').dataset.token

          this.$store.dispatch('inits/storeConfig',{token:this.token,logoUrl:this.logourl,agbUrl:this.agburl,strWelcome:this.strwelcome,noccheckout:this.nocheckout}).then(()=>{
                if(this.stateAuth===1){
                    if(!this.$store.getters['inits/noCheckout']) {
                        //console.log(this.$store.getters['inits/noCheckout'])
                        const script = document.createElement("script")
                        const ClientID = this.paypal_client_id
                        const merchantID = this.storeConfig.store_paypal_email
                        const mode = this.storeConfig.paypal_mode
                        if (mode === 'live') {
                            script.src = `https://www.paypal.com/sdk/js?client-id=${ClientID}&currency=EUR&locale=de_DE&merchant-id=${merchantID}`;
                        } else {
                            script.src = `https://www.paypal.com/sdk/js?client-id=${ClientID}&currency=EUR&buyer-country=DE&locale=de_DE&merchant-id=${merchantID}`;
                        }
                        script.addEventListener("load", this.payPalScriptLoaded);
                        //document.body.appendChild(script);
                        document.head.appendChild(script);
                    }

                    if(window.getEtsAddress){
                      const etsAddress=window.getEtsAddress();
                      Object.keys(etsAddress).map(k => etsAddress[k] = typeof etsAddress[k] == 'string' ? etsAddress[k].trim() : etsAddress[k]);
                      this.$store.dispatch('cart/setBillingAdr',etsAddress)
                    }
                    this.$store.dispatch('inits/suppliers').then(()=>{
                        if(this.stateAuth===3) {
                         if(this.loadMachineId){
                            this.$store.dispatch('explodedView/machineId',this.loadMachineId).then(()=> {
                              this.$router.replace('/exploded-view')
                            })
                          }
                          else{
                            this.$router.replace('/')
                          }

                        }
                    })
                }
            })
        },
       methods:{
            payPalScriptLoaded(){
                this.$store.dispatch('cart/payPalScriptLoaded')
            }
       }
       /* beforeCreate: function(){
            const token=document.getElementById('app').dataset.token
            this.$store.dispatch('inits/getStoreConfig',{token:token}).then(res=>{
                console.log('ires',res)
                if(res){
                    this.$store.dispatch('inits/getSuppliers')
                }
                else{
                    this.$router.push('/unauthorized')
                }

            },err=>{
                if(err){
                    this.$router.push('/unauthorized')
                }
            })
        },*/
    }
</script>

<style scoped>


</style>

<style>
/*  @import '~bootstrap/dist/css/bootstrap.css';
   @import '~bootstrap-vue/dist/bootstrap-vue.css';*/
    #app {
        border: 1px solid black;
        height:90vh;
        display: flex;
        position: relative;
       overflow-y: auto;
     /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";*/
    }
    [type=checkbox]:checked+label:before {
        background-color: #5a6268 !important;
        border-color:#5a6268 !important;
    }

    [type=checkbox]:focus+label:before {

        border-color:#5a6268 !important;
    }
    .form-control:focus{
        border-color: lightgrey !important;
        box-shadow: 0 0 0 .2rem rgb(211,211,211) !important;
    }
    option:hover{
        background: #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='red' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right .75rem center/8px 10px !important;

        background-color: red !important;
    }

    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    /* Firefox */
    input[type=number] {
        -moz-appearance: textfield;
    }

    .inwidth{
        min-width: 2ch;
        max-width:5ch;
    }

    .rinwidth{
      min-width: 3ch;
      max-width:10ch;
    }

</style>





