<template>
    <b-container>
        <h4 class="pt-3 text-center">Warenkorb</h4>
        <p class="text-center" v-if="cartProducts.length===0">Der Warenkorb ist leer</p>
        <div v-else>
            <b-row class="mr-0 ml-0">
                <b-col>
                    <b-table
                            :items="cartProducts"
                            id="basket-table"
                            :fields="fields"
                            primary-key="index"
                            stacked="sm"
                    >
                        <template #cell(qty)="data" >
                            <b-input-group size="sm">
                                <b-form-input
                                        :value="data.value"
                                        :ref="'pqty'+data.index"
                                        type="number"
                                        min="0"
                                        class="inwidth"
                                >
                                </b-form-input>

                                <b-input-group-append>
                                    <b-button  @click="updateItem(data)">
                                        <b-icon    icon="arrow-repeat" aria-hidden="true"></b-icon>
                                    </b-button>
                                </b-input-group-append>
                            </b-input-group>
                            <!-- <b-row>
                                <b-col class="text-right mr-0">
                                    <b-input :value="data.value" :ref="'pqty'+data.index" type="number" min="0"></b-input>

                                </b-col>

                                <b-col class="text-left ml-0">
                                    <b-button @click="updateItem(data)">
                                        <b-icon   icon="arrow-repeat" aria-hidden="true"></b-icon>
                                    </b-button>
                                </b-col>
                            </b-row>-->
                        </template>

                      <template #cell(ref)="data" >
                        <b-input-group size="sm">
                          <b-form-input
                              :value="data.value"
                              :ref="'pref'+data.index"
                              type="text"
                              class="rinwidth"
                              @input="ref=>updateRef(data,ref)"
                          >
                          </b-form-input>
                        <!--  <b-input-group-append>
                            <b-button  @click="updateRef(data)">
                              <b-icon    icon="arrow-repeat" aria-hidden="true"></b-icon>
                            </b-button>
                          </b-input-group-append>-->
                        </b-input-group>
                      </template>

                        <template #cell(product)="data">
                            <b-button size="sm" @click="deleteItem(data.value)">
                                <b-icon   icon="cart-x" aria-hidden="true"></b-icon>
                            </b-button>
                        </template>

                    </b-table>

                </b-col>
            </b-row>

            <b-row class="ml-0 mr-0">
                <b-col sm="6" offset-sm="6" >

                    <b-table
                            stacked=""
                            :items="[cartTotals]"
                            :fields="totalfields"
                    >
                    </b-table>

                </b-col>
            </b-row>
          <b-row class="mr-0 ml-0">
            <b-col sm="6" offset-sm="6" >
              <b-form-group
                  label-for="orderRef"
                  label="Referenz Bestellung"
              >
                <b-form-input
                    :value="basketRef"
                    id="bref"
                    @input="value=>setBasketRef(value)"

                >
                </b-form-input>
              </b-form-group>
            </b-col>
          </b-row>
            <b-row class="mr-0 ml-0">
                <b-col sm="6" offset-sm="6" >
                    <b-button  :to="{name:'check-out'}" exact>Bestellen</b-button>
                </b-col>
            </b-row>
        </div>

    </b-container>
</template>


<script>
    import helpers from "../service/helpers";

    export default {
        name: "Basket",
        data(){
            return {
                fields: [
                    {key: 'product.name', label: 'Artikel'},
                    {key: 'product.sku', label: 'Artikelnr'},
                    {key: 'product.store_price', label: 'Preis',formatter:(value)=>this.formatMoney(value)},
                    {key: 'qty', label: 'Menge'},
                    {key:'ref',label:'Referenz'},
                    {key:'product',label:'entfernen'},
                    {key:'sum',label:'Summe',formatter:(value)=>this.formatMoney(value)}
                ],
                totalfields:[
                    {key:'product_total',label:'Warenwert',formatter:(value)=>this.formatMoney(value),tdClass:'tdright'},
                    {key:'shipping',label:'Versand',formatter:(value)=>this.formatMoney(value),tdClass:'tdright'},
                    {key:'handling',label:'Mindermengenzuschlag',formatter:(value)=>this.formatMoney(value),tdClass:'tdright'},
                    {key:'totaltax',label:'Mehrwertsteuer',formatter:(value)=>this.formatMoney(value),tdClass:'tdright'},
                    {key:'grosstotal',label:'Gesamt',formatter:(value)=>this.formatMoney(value),tdClass:'tdright'}
                ]
            }
        },
        computed:{
            cartProducts(){
                return this.$store.getters['cart/cartProducts']
            },
            cartTotals(){
                return this.$store.getters['cart/totals']
            },
            basketRef(){
              return this.$store.getters['cart/getBasketRef']
            }
        },
        methods:{
            formatMoney(value){
              return helpers.formatMoney(value)+'€'
            },
            deleteItem(itm){
               this.$store.dispatch('cart/removeProduct',itm)
            },
            updateItem(data){
                const qty=this.$refs['pqty'+data.index].$el._value
                const product=data.item.product
                //console.log('val',data.item.product)
                this.$store.dispatch('cart/addProduct',{product:product,qty:qty})
            },
          updateRef(data,ref){
            //  const ref=this.$refs['pref'+data.index].$el._value
            const product=data.item.product
            this.$store.dispatch('cart/updateRef',{product:product,ref:ref})

          },
          setBasketRef(val){
              this.$store.dispatch('cart/setBasketRef',val)
          }
        }
    }
</script>

<style >
    .tdright{
        text-align: right;
    }
</style>