<template>
   <b-container>
       <h1 class="pt-3 text-center">Über den Ersatzteilshop</h1>
       <b-row class="ml-3">
           <b-col >
               <p class="lead">Rechnung und Versand</p>
               <p>Rechnung und Versand kann ausschließlich nach Deutschland erfolgen.</p>
           </b-col>
       </b-row>
       <b-row class="ml-3">
           <b-col >
               <p class="lead">Versandkosten</p>
               <p>Pro Bestellung werden Versandkosten von {{formatMoney(inits.store_shipping)}}
                   Netto bzw. {{formatMoney(shipping_gross)}} Brutto berechnet.</p>
               <p v-if="inits.store_shipping_threshhold>0">
                   Ab einem Warenwert von {{formatMoney(inits.store_shipping_threshhold)}} Netto entfallen die Versandkosten.
               </p>
           </b-col>
       </b-row>

       <b-row class="ml-3" v-if="inits.store_handling>0">
           <b-col >
               <p class="lead">Mindermengenzuschlag</p>
               <p>Bei einem Warenwert von unter  {{formatMoney(inits.store_handling_threshhold)}} Netto müssen wir leider einen Mindermengenzuschlag von
                   {{formatMoney(inits.store_handling)}} Netto bzw {{formatMoney(handling_gross)}} Brutto berechnen.</p>
           </b-col>
       </b-row>
       <b-row class="ml-3" v-if="inits.store_handling>0">
           <b-col >
               <p class="lead">AGB</p>
               <p>Unsere allgemeinen Geschäftsbedingungen können sie <b-link :href="agbUrl" target="_blank">hier</b-link> einsehen</p>
           </b-col>
       </b-row>
   </b-container>
</template>
<script>
    import helpers from "../service/helpers";
    export default {
        name: "About",
        computed:{
            inits(){
                return this.$store.getters['inits/storeConfig']
            },
            shipping_gross(){
                return this.$store.getters['inits/shipping_gross']
            },
            handling_gross(){
                return this.$store.getters['inits/handling_gross']
            },
            agbUrl() {
                return this.$store.getters['inits/agbUrl']
            }
        },
        methods: {
            formatMoney(value) {
                return helpers.formatMoney(value) + '€'
            }
        }
    }
</script>
