<template >
    <div  id="mapApp" ref="mapApp" v-if="loadState===2"><!-- -->
        <div id="mapTitle" ref="mapTitle" class="text-center pt-3">
            <h4>{{machine.supplier.name}} {{machine.name}}</h4>
        </div>

        <div id="mapToolbar" ref="mapToolbar">
            <b-button-toolbar class="mb-1" >
                <b-dropdown v-show="!showSearch" size="sm" class="mx-1" right :text="'Zeichnung '+drawing.list_num">
                    <b-dropdown-item-button v-for="(drawing,index) in machine.drawing" :key="drawing.list_num" @click="setCurrentList(index)">Zeichnung {{drawing.list_num}}</b-dropdown-item-button>
                </b-dropdown>
                <b-button-group v-show="showdrawing&&!showSearch" size="sm" class="mx-1">
                    <b-button @click="zoomIn"> <b-icon icon="zoom-in"  aria-hidden="true"></b-icon> </b-button>
                    <b-input size="sm" readonly  :value="Math.round(this.zoom*100)+'%'" class="zoom"></b-input>
                    <b-button @click="zoomOut"><b-icon icon="zoom-out" aria-hidden="true"></b-icon></b-button>
                </b-button-group>
                <b-button v-show="showdrawing&&!showSearch" size="sm" @click="fitViewPort" class="mx-1">
                    <b-icon icon="aspect-ratio" area-hidden="true"></b-icon>
                </b-button>
                <b-button v-show="!showSearch" size="sm" class="mx-1"  @click="switchView()"  >
                  <b-icon :icon="showdrawing?'list':'image-fill'"></b-icon>
                  {{ showdrawing?'Zeige Liste':'Zeige Zeichnung' }}
                </b-button>
                <b-button size="sm" class="mx-1"  @click="switchSearch()"  >
                  <b-icon v-if="!showSearch" icon="square"></b-icon>
                  <b-icon v-else icon="check-square"></b-icon>
                  &nbsp;
                  <b-icon icon="search"></b-icon> Suche
                </b-button>
                <b-button v-show="!showSearch" size="sm" class="mx-1" @click="print()">
                  <b-icon icon="printer"></b-icon> drucken
                </b-button>
            </b-button-toolbar>
        </div>

        <div id="mapStage" ref="mapStage" @mouseup="mouseMapUp" @mousemove="mouseMapMove"  @mouseleave="mouseMapUp"><!-- v-touch:moving="mouseMapMove"  -->
            <div v-show="showdrawing&&!showSearch" id="mapArea" :style="mapCoordinates" @mousedown="mouseMapDown"><!-- drag -->
                <order-card ref="ordercard"></order-card>
                <map :id="'m'+drawing.id" :name="'m'+drawing.id">
                    <area v-for="(hspot,index) in drawing.hotspot"
                          :key="hspot.id" :id="'spot'+hspot.id"
                          shape="circle"
                          :coords="spotCoords(index)"
                          @click="spotClicked(index,$event)"
                          @mouseover="spotOver(index,$event)"
                          @mouseout="spotOut($event)"
                          href="#"
                          :alt="hspot.posnum"

                    >
                </map>
                <img
                        :usemap="'#m'+drawing.id"
                        :style="imgStyle"
                        draggable="false"
                        :src="imgSrc"
                        :alt="machine.name"
                />
            </div>
            <div v-show="!showdrawing&&!showSearch">
                <b-container fluid>
                <b-table
                        :items="drawing.hotspot"
                        :fields="tfields"
                        primary-key="id"
                        id="evresult-table"
                        show-empty
                        empty-text="Keine Positionen verfügbar"
                        responsive="true"
                        stacked="sm"
                >
                    <template #cell(qty)="data" >
                        <b-input-group size="sm">
                            <b-form-input
                                    :value="cartQty(data.item.product)>0?cartQty(data.item.product):1"
                                    :ref="'pqty'+data.index"
                                    type="number"
                                    min="0"
                                    size="sm"
                                    no-wheel
                                    class="inwidth"
                            >
                            </b-form-input>

                            <b-input-group-append>
                                <b-button  @click="addToCart(data)">
                                    <b-icon    icon="cart" aria-hidden="true"></b-icon>
                                </b-button>
                            </b-input-group-append>
                        </b-input-group>
                        <!-- <b-row class="m-0">
                            <b-col class="text-right  m-0">
                                <b-input :value="cartQty(data.item.product)" :ref="'pqty'+data.index" type="number" min="0"></b-input>
                            </b-col>
                            <b-col class="text-left m-0">
                                <b-button @click="addToCart(data)">
                                    <b-icon    icon="cart" aria-hidden="true"></b-icon>
                                </b-button>
                            </b-col>
                        </b-row>-->
                    </template>
                    <template #cell(available)="data">
                        <b-row class="m-0" >
                            <b-col class="m-0" :style="availableInfo(data).style">
                                <b-icon icon="truck" aria-hidden="true"></b-icon>&nbsp;{{availableInfo(data).txt}}
                            </b-col>
                        </b-row>
                    </template>
                    <template #cell(index)="data">
                        <b-button size="sm" @click="tableSpotClicked(data.index,$event)">
                            <b-icon icon="geo-fill" area-hidden="true"></b-icon>
                        </b-button>
                    </template>
                </b-table>
                </b-container>
            </div>
            <div v-show="showSearch">
                <b-container fluid>
                <b-row>
                    <b-col lg="6" class="my-1">
                <b-form-group
                        label="Suche im Gerät"
                        label-for="filter-input"
                        label-cols-sm="3"
                        label-align-sm="right"
                        label-size="sm"
                        class="mb-0 mt-2"
                >
                    <b-input-group size="sm">
                        <b-form-input
                                id="filter-input"
                                v-model="filter"
                                type="search"
                                placeholder="Position, Ersatzteil, Artikelnummer"
                        >
                        </b-form-input>

                        <b-input-group-append>
                            <b-button :disabled="!filter" @click="filter = ''">löschen</b-button>
                        </b-input-group-append>
                    </b-input-group>
                </b-form-group>
                    </b-col>
                    <b-col lg="6" class="my-1">
                        <b-form-group
                                label="Suche nach"
                                description="ohne Auswahl wird nach allem gesucht"
                                label-cols-sm="3"
                                label-align-sm="right"
                                label-size="sm"
                                class="mb-0"
                                v-slot="{ ariaDescribedby }"
                        >
                            <b-form-checkbox-group
                                    v-model="filterOn"
                                    :aria-describedby="ariaDescribedby"
                                    class="mt-1"
                            >
                                <b-form-checkbox value="posnum">Position</b-form-checkbox>
                                <b-form-checkbox value="productName">Ersatzteil</b-form-checkbox>
                                <b-form-checkbox value="productSku">Artikelnummer</b-form-checkbox>
                            </b-form-checkbox-group>
                        </b-form-group>
                    </b-col>
                </b-row>
                <b-table
                    :items="hotspots"
                    id="shresult-table"
                    primary-key="id"
                    :fields="hsfields"
                    :filter="filter"
                    :filter-ignored-fields="['id']"
                    :filter-included-fields="filterOn"
                    show-empty
                    v-show="filter"
                    empty-filtered-text="Keine Artikel gefunden"
                    stacked="sm"
                >
                    <template #cell(listpos)="data">
                        {{data.item.list_num}}/{{data.item.posnum}}
                    </template>
                    <template #cell(id)="data">
                        <b-button size="sm" @click="showHotSpotById(data.value)">
                            <b-icon icon="geo-fill" area-hidden="true"></b-icon>
                        </b-button>
                    </template>
                </b-table>
                </b-container>
            </div>
            <div v-show="false">
                <b-container fluid id="printContainer" ref="printContainer">
                   <b-card v-for="(adrawing,idx) in machine.drawing"
                           :key="adrawing.id"
                   >
                       <b-card-img
                        :src="imgSrcByDrawingIndex(idx)"
                        :style="{maxHeight:'210mm'}"
                        top>
                       </b-card-img>
                       <b-card-title>{{machine.supplier.name}} {{machine.name}} - Stückliste {{adrawing.list_num}}</b-card-title>
                       <b-table
                       :items="adrawing.hotspot"
                       :id="'adrawing'+adrawing.id"
                       :fields="pfields"
                       show-empty
                       empty-text="Keine Positionen verfügbar"
                       responsive="true"
                       >

                       </b-table>

                   </b-card>
                </b-container>
            </div>
        </div>
    </div>

    <div v-else-if="loadState===1" class="mt-5 text-center">
        <b-spinner class="mt-5"   label="Loading..."></b-spinner>
        <p>Lade Zeichnung</p>
    </div>

    <div v-else-if="loadState===0" class="mt-5 text-center">
        <b-icon class="h2 mt-5" icon="exclamation-circle-fill" variant="info"></b-icon>
        <p>Keine Zeichnung geladen</p>
    </div>

    <div v-else class="mt-5 text-center">
        <b-icon class="h2 mt-5" icon="exclamation-circle-fill" variant="danger"></b-icon>
        <p>Fehler beim Laden der Explosionszeichnung</p>
    </div>
</template>

<script>
    import OrderCard from "../components/OrderCard";
    import helpers from "../service/helpers";
  //  import printJS from "print-js"
    import * as printJS from "print-js";
   // import { isMobile } from 'mobile-device-detect';
    export default {
        name: "ExplodedView",
        components: {OrderCard},
        props:['ilist','ipos'],
        data(){
          return{
              mapCoordinates: {
                  top: "0px",
                  left: "0px",
                  height:"0px",
                  width:"0px",
              },
              mapOffset: [0, 0],
              mapClickState:false,
              spotRadius:13,
              tfields: [
                  {key:'posnum',label:'Position'},
                  {key: 'product.name', label: 'Ersatzteil'},
                  {key: 'product.sku', label: 'Artikelnr'},
                  {key: 'product.store_price', label: 'Preis netto',formatter:(value)=>helpers.formatMoney(value)+'€',tdClass:'text-md-right text-sm-left'},
                  {key: 'product.gross_store_price', label: 'Preis brutto',formatter:(value)=>helpers.formatMoney(value)+'€',tdClass:'text-md-right text-sm-left'},
                  {key:'available',label:'Verfügbarkeit'},
                  {key:'index',label:'',tdClass: 'text-center'},
                  {key:'qty',label:'Bestellen'}
              ],
              hsfields:[
                  {key:'listpos',label:'Liste/Postition'},
                  {key:'productName',label:'Ersatzteil'},
                  {key:'productSku',label:'Artikelnummer'},
                  {key:'id',label:'',tdClass:'text-center'}
              ],
              pfields:[
                  {key:'posnum',label:'Position'},
                  {key: 'product.name', label: 'Ersatzteil'},
                  {key: 'product.sku', label: 'Artikelnr'},
                  {key: 'product.store_price', label: 'Preis netto',formatter:(value)=>helpers.formatMoney(value)+'€',tdClass:'text-right'},
                  {key: 'product.gross_store_price', label: 'Preis brutto',formatter:(value)=>helpers.formatMoney(value)+'€',tdClass:'text-right'}
              ],
              filter:null,
              filterOn: [],
              showdrawing:true,
              showSearch:false,


          }
        },
        methods:{

            areaStyle(){
                this.mapCoordinates.height=this.imgStyle.height+'px'
                this.mapCoordinates.width=this.imgStyle.width+'px'
                console.log('astyle')
                return this.mapCoordinates
            },
            setStageDims(){
              //  this.$nextTick(()=>{
                   // this.shadowRoot

                    /*const appHeight= Number(document.getElementById('app').offsetHeight)
                    const headHeight=Number(document.getElementById('navBar').offsetHeight)
                    const titleHeight = Number(document.getElementById('mapTitle').offsetHeight)
                    const  toolbarHeight = Number(document.getElementById('mapToolbar').offsetHeight)
                    const mapStage = document.getElementById('mapStage')
                    const appWidth= Number(document.getElementById('app').offsetWidth)*/

                   const appHeight= Number(this.$parent.$el.offsetHeight)
                   const headHeight=Number(this.$parent.$refs.navBar.$el.offsetHeight)
                   const titleHeight = Number(this.$refs.mapTitle.offsetHeight)
                   const  toolbarHeight = Number(this.$refs.mapToolbar.offsetHeight)
                   const mapStage = this.$refs.mapStage
                   const appWidth= Number(this.$parent.$el.offsetWidth)
                    console.log(this.$parent.$refs.navBar.$el.offsetHeight)
                    mapStage.style.maxHeight= (appHeight-headHeight-titleHeight-toolbarHeight-14)+'px'
              //mapStage.style.maxHeight= (appHeight-titleHeight-toolbarHeight-14)+'px'
                    mapStage.style.maxWidth= appWidth-2+'px'
                    mapStage.style.height= (appHeight-headHeight-titleHeight-toolbarHeight-14)+'px'
             // mapStage.style.height= (appHeight-titleHeight-toolbarHeight-14)+'px'
                    mapStage.style.width= appWidth-2+'px'


                //})
            },
            mouseMapDown(e){
                this.mapClickState = true;
                e.target.style.cursor='move'
                this.mapOffset = [
                    e.currentTarget.offsetLeft - e.clientX,
                    e.currentTarget.offsetTop - e.clientY
                ];

            },
            mouseMapUp(e){
                e.target.style.cursor=''
                this.mapClickState = false;
            },
            mouseMapMove(e){
                if (this.mapClickState) {
                    this.mapCoordinates.left = (e.clientX + this.mapOffset[0]) + "px";
                    this.mapCoordinates.top = (e.clientY + this.mapOffset[1]) + "px";
                }
            },
            setCurrentList(index){
                this.$store.dispatch('explodedView/drawingIndex',index)
            },
            zoomIn(){
                this.$store.dispatch('explodedView/zoom',.1)
            },
            zoomOut(){
                this.$store.dispatch('explodedView/zoom',-.1)
            },
            spotCoords(index){
                const spot=this.drawing.hotspot[index]
                //$(this).attr('coords',(hdata.posx*zoomFactor)+","+(hdata.posy*zoomFactor)+","+(areaRadius*zoomFactor));
                const coordx=spot.posx*this.zoom
                const coordy=spot.posy*this.zoom
                const radius=this.spotRadius*this.zoom
                return coordx+','+coordy+','+radius

            },
            spotClicked(index,event){
                event.preventDefault()
                this.$store.dispatch('explodedView/hotspotIndex',index)
                this.$store.dispatch('explodedView/hotspotVisibleSupplierInfo',false)
                this.$store.dispatch('explodedView/hotspotVisibleInfo', true)
                this.$store.dispatch('explodedView/hotspotVisibleOrder',true)
                //this.$refs['ordercard'].$el.style.left='300px'
                //console.log(this.$refs['ordercard'].$el.getBoundingClientRect())
            },
            spotOver(index,event){
                if(!this.spotVisibleOrder) {
                    event.target.focus()
                    this.$store.dispatch('explodedView/hotspotIndex', index)
                    this.$store.dispatch('explodedView/hotspotVisibleInfo', true)
                }
            },
            spotOut(event){
                event.target.blur()
                if(!this.spotVisibleOrder) {
                    this.$store.dispatch('explodedView/hotspotVisibleInfo', false)
                }
            },
            switchView(){
                this.showSearch=false
                this.showdrawing = !this.showdrawing;
                this.adjustOverflow()
            },
            switchSearch(){
              this.showSearch=!this.showSearch
              this.adjustOverflow()
            },
            adjustOverflow(){
                //const mapStage = document.getElementById('mapStage')
              const mapStage = this.$refs.mapStage
                if (!this.showdrawing||this.showSearch) {
                    mapStage.style.overflow = 'auto'
                } else {
                    mapStage.style.overflow = 'hidden'
                }
            },
            tableSpotClicked(index,event){
                this.switchView()
                this.fitViewPort()
                this.spotClicked(index,event)
            },
            addToCart(data){
                const qty=this.$refs['pqty'+data.index].$el._value
                const product=data.item.product
                //console.log('val',data.item.product)
                if(window.etsAddToCart){
                    let etsProduct=helpers.cleanSource(product)
                    etsProduct.qty=qty
                    window.etsAddToCart(helpers.cleanSource(etsProduct))
                }
                this.$store.dispatch('cart/addProduct',{product:product,qty:qty})
            },
            availableInfo(data){
                const product=data.item.product
                const ret={
                    txt:'',
                    style:''

                }
                switch (product.available) {
                    case 0:
                        ret.txt = "Nicht mehr Lieferbar!";
                        ret.style = "color:red;";
                        break;
                    case 2:
                        ret.txt = "befriedigende Verfügbarkeit";
                        ret.style = "color:green;"
                        break;
                    case 3:
                        ret.txt = "kritische Verfügbarkeit";
                        ret.style = "color:orange";
                        break;
                    case 4:
                        ret.txt = "Auf Anfrage!";
                        ret.style = "color:red";
                        break;
                    default:
                        ret.txt = "(sehr) gute Verfügbarkeit";
                        ret.style = "color:green";
                }
                return ret;
            },
            fitViewPort(){
                const mapStageDims = this.$refs.mapStage.getBoundingClientRect()
                let fpZoom=mapStageDims.width/this.drawing.scale_x;
                if(this.drawing.scale_x*fpZoom>mapStageDims.height){
                    fpZoom=mapStageDims.height/this.drawing.scale_y
                }
                this.$store.dispatch('explodedView/zoom',fpZoom-this.zoom)
                this.mapCoordinates.top=0
                this.mapCoordinates.left=0
            },
            showPropertySpot(){
                const iDrawingIndex=this.$store.getters['explodedView/getDrawingIndexFromNum'](this.ilist)
                if(iDrawingIndex>=0){
                    this.setCurrentList(iDrawingIndex)
                    this.fitViewPort()
                    const iHotspotIndex=this.$store.getters['explodedView/getHotspotIndexFromPos'](this.ipos)
                    if(iHotspotIndex>=0){
                        this.$store.dispatch('explodedView/hotspotIndex',iHotspotIndex)
                        this.$store.dispatch('explodedView/hotspotVisibleInfo', true)
                        this.$store.dispatch('explodedView/hotspotVisibleOrder',true)
                    }
                }
            },
            showHotSpotById(hid){
                const hotspotInfo=this.$store.getters['explodedView/getHotspotInfoById'](hid)
                if((hotspotInfo.drawingIndex>=0)&&(hotspotInfo.hotspotIndex>=0)){
                    this.showSearch=false;
                    this.showdrawing=true;
                    this.setCurrentList(hotspotInfo.drawingIndex)
                    this.adjustOverflow()
                    this.fitViewPort()
                    this.$store.dispatch('explodedView/hotspotIndex',hotspotInfo.hotspotIndex)
                    this.$store.dispatch('explodedView/hotspotVisibleInfo', true)
                    this.$store.dispatch('explodedView/hotspotVisibleOrder',true)
                }

            },
            print(){

                const header='<h4>'+this.$store.getters['inits/storeConfig'].company
                    + '<br />Stückliste ' +this.machine.supplier.name+' '+this.machine.name
                    +'<br/>Preise gültig am ' + (new Date()).toLocaleDateString()+'</h4>'
                //printJS({printable:'printContainer',type:'html',header:header})
              printJS({printable:this.$refs.printContainer,type:'html',header:header})

            },
            imgSrcByDrawingIndex(idx){
                return process.env.VUE_APP_MEDIA_SERVER+this.machine.drawing[idx].image
            },


        },
        computed:{
            machine(){
                return this.$store.getters['explodedView/machine']
            },
            hotspots(){
                return this.$store.getters['explodedView/hotspots']
            },
            machineId(){
              return this.$store.getters['explodedView/machineId']
            },
            drawing(){
                return this.$store.getters['explodedView/drawing']
            },
            imgStyle(){
                /*const y=this.zoom*this.drawing.scale_y+'px'
                const x=this.zoom*this.drawing.scale_x+'px'*/
                return {
                    height:this.$store.getters['explodedView/drawingScaleY'],
                    width: this.$store.getters['explodedView/drawingScaleX']
                }
            },
            imgSrc(){
                return process.env.VUE_APP_MEDIA_SERVER+this.drawing.image
            },

            zoom(){
                return this.$store.getters['explodedView/zoom']
            },
            spotVisibleOrder(){
                return this.$store.getters['explodedView/hotspotVisibleOrder']
            },
            loadState(){
                return this.$store.getters['explodedView/loadState']
            },
            cartQty(){
                return this.$store.getters['cart/cartProductQty']
            }
        },
        mounted() {
            if(this.machineId!==this.machine.id) {
                this.$store.dispatch('explodedView/machine').then(() => {
                    if (this.loadState === 2) {
                        this.$nextTick(() => {
                            this.setStageDims()
                            this.fitViewPort()
                            this.showPropertySpot()
                        })
                    }
                })
            }
            else{
                this.$nextTick(() => {
                    this.setStageDims()
                    this.showPropertySpot()

                })
            }

        },
        created() {
            window.addEventListener("resize", this.setStageDims);
        },
        destroyed() {
            window.removeEventListener("resize", this.setStageDims);
        },
        watch:{
            machineId(newVal,oldVal){
                if(newVal!==oldVal){
                    this.$store.dispatch('explodedView/machine').then(()=>{
                        this.fitViewPort()
                        this.showPropertySpot()
                    })
                }
            }
        },
    }
</script>

<style scoped>
    #mapStage{
        position: relative;
        overflow: hidden;
        margin:0;
        padding:0;
        width: 100%;
        border-top: 1px solid black
    }

    #mapArea{
    position: absolute;
    }

    .zoom{
        width:7ch;
        /*box-sizing:content-box;
        text-align: center;*/
    }




</style>
<style>


</style>