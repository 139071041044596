<template>
    <b-overlay :show="paymentInProgress">
    <b-card
        title="Bestellung"
    >
        <b-card-body>
            <b-row>
                <b-col lg="8" class="mb-4">
                    <b-card>
                    <b-tabs card  pills justified  v-model="tabIndex" @activate-tab="tabActivated">
                        <b-tab  title="Adressdaten erfassen" title-item-class="mytab">
                            <b-card-title>Rechnungsadresse</b-card-title>
                        <b-card-text>
                            <b-form @submit="validateAdress" ref="adrForm">
                                <b-form-group
                                        v-show="billingAdr.custno.length>0"
                                        label="Kundennummer"
                                        label-for="custno"
                                >
                                    <b-form-input
                                            id="custno"
                                            v-model="billingAdr.custno"
                                            type="text"
                                            readonly
                                    >
                                    </b-form-input>
                                </b-form-group>
                                <div class="row">
                                    <div class="col-md-6 mb-4">
                                        <b-form-group
                                        label="Anrede"
                                        label-for="salutation"
                                        >
                                            <b-form-select
                                                    id="salutation"
                                                    name="salutation"
                                                    v-model="billingAdr.salutation"
                                                    :options="['Herr','Frau']"
                                                    required
                                                    class="form-control"

                                            >
                                            </b-form-select>
                                        </b-form-group>
                                    </div>
                                </div>
                                <!--Grid row-->
                                <div class="row">
                                    <!--Grid column-->
                                    <div class="col-md-6 mb-4">
                                        <!--firstName-->
                                        <b-form-group
                                            label="Vorname"
                                            label-for="firstname"
                                        >
                                            <b-form-input
                                                id="firstname"
                                                type="text"
                                                v-model="billingAdr.firstname"
                                                required
                                                pattern=".*\S.*"
                                                oninvalid="setCustomValidity('Bitte füllen Sie dieses Feld aus')"
                                                oninput="setCustomValidity('')"
                                            >
                                            </b-form-input>
                                        </b-form-group>
                                    </div>
                                    <!--Grid column-->
                                    <!--Grid column-->
                                    <div class="col-md-6 mb-2">
                                        <!--lastName-->
                                        <b-form-group
                                                label="Nachname"
                                                label-for="lastname"
                                        >
                                            <b-form-input
                                                    id="lastname"
                                                    type="text"
                                                    v-model="billingAdr.lastname"
                                                    required
                                                    pattern=".*\S.*"
                                                    oninvalid="setCustomValidity('Bitte füllen Sie dieses Feld aus')"
                                                    oninput="setCustomValidity('')"
                                            >
                                            </b-form-input>
                                        </b-form-group>

                                    </div>
                                    <!--Grid column-->
                                </div>
                                <!--Grid row-->
                                <!--email-->
                                <b-form-group
                                        label="Firma"
                                        label-for="company"
                                >
                                    <b-form-input
                                            id="company"
                                            v-model="billingAdr.company"
                                            type="text"
                                    >
                                    </b-form-input>
                                </b-form-group>
                                <b-form-group
                                    label="E-Mail-Adresse"
                                    label-for="email"
                                >
                                    <b-form-input
                                        id="email"
                                        v-model="billingAdr.email"
                                        type="email"
                                        required
                                    >
                                    </b-form-input>
                                </b-form-group>
                                <div class="row">
                                    <!--Grid column-->
                                    <div class="col-md-10 mb-4">
                                        <b-form-group
                                            label-for="street"
                                            label="Strasse"
                                        >
                                            <b-form-input
                                                id="street"
                                                v-model="billingAdr.street"
                                                required
                                                type="text"
                                                pattern=".*\S.*"
                                                oninvalid="setCustomValidity('Bitte füllen Sie dieses Feld aus')"
                                                oninput="setCustomValidity('')"
                                            >
                                            </b-form-input>
                                        </b-form-group>
                                    </div>
                                    <div class="col-md-2 mb-4">
                                        <b-form-group
                                                label-for="street_no"
                                                label="Nr."
                                        >
                                            <b-form-input
                                                    id="street_no"
                                                    v-model="billingAdr.street_no"
                                                    required
                                                    type="text"
                                                    pattern=".*\S.*"
                                                    oninvalid="setCustomValidity('Bitte füllen Sie dieses Feld aus')"
                                                    oninput="setCustomValidity('')"
                                            >
                                            </b-form-input>
                                        </b-form-group>
                                    </div>
                                </div>


                                <!--Grid row-->
                                <div class="row">

                                    <!--Grid column-->
                                    <div class="col-md-4 mb-4">
                                        <b-form-group
                                            label-for="postalcode"
                                            label="PLZ"
                                        >
                                            <b-form-input
                                                v-model="billingAdr.postalcode"
                                                id="postalcode"
                                                required
                                                type="text"
                                                pattern=".*\S.*"
                                                oninvalid="setCustomValidity('Bitte füllen Sie dieses Feld aus')"
                                                oninput="setCustomValidity('')"
                                            >
                                            </b-form-input>
                                        </b-form-group>
                                    </div>
                                    <div class="col-md-8 mb-4">
                                        <b-form-group
                                            label-for="city"
                                            label="Ort"
                                        >
                                            <b-form-input
                                                v-model="billingAdr.city"
                                                id="city"
                                                required
                                                type="text"
                                                pattern=".*\S.*"
                                                oninvalid="setCustomValidity('Bitte füllen Sie dieses Feld aus')"
                                                oninput="setCustomValidity('')"
                                            >

                                            </b-form-input>
                                        </b-form-group>
                                    </div>
                                    <!--Grid column-->
                                </div>

                                <b-form-group
                                    label="Land"
                                    label-for="country"
                                >
                                    <b-form-select
                                        id="country"
                                        v-model="billingAdr.country_id"
                                        :options="{DE:'Deutschland'}"
                                        required
                                        class="form-control"
                                    >
                                    </b-form-select>
                                </b-form-group>
                                <!--Grid row-->
                                <b-form-group>
                                    <b-form-checkbox
                                            id="sameadr"
                                            v-model="sameadr"
                                    >
                                    Rechnungsadresse ist Lieferadresse
                                    </b-form-checkbox>
                                </b-form-group>
                                <hr>
                                <div v-show="!sameadr">
                                <b-card-title>Lieferadresse</b-card-title>
                                    <div class="row">
                                        <div class="col-md-6 mb-4">
                                            <b-form-group
                                                    label="Anrede"
                                                    label-for="ssalutation"
                                            >
                                                <b-form-select
                                                        id="ssalutation"
                                                        name="ssalutation"
                                                        v-model="shippingAdr.salutation"
                                                        :options="['Herr','Frau']"
                                                        :required="!sameadr"
                                                        class="form-control"
                                                >
                                                </b-form-select>
                                            </b-form-group>
                                        </div>
                                    </div>
                                    <!--Grid row-->
                                    <div class="row">
                                        <!--Grid column-->
                                        <div class="col-md-6 mb-4">
                                            <!--firstName-->
                                            <b-form-group
                                                    label="Vorname"
                                                    label-for="sfirstname"
                                            >
                                                <b-form-input
                                                        id="sfirstname"
                                                        type="text"
                                                        v-model="shippingAdr.firstname"
                                                        :required="!sameadr"
                                                >
                                                </b-form-input>
                                            </b-form-group>
                                        </div>
                                        <!--Grid column-->
                                        <!--Grid column-->
                                        <div class="col-md-6 mb-2">
                                            <!--lastName-->
                                            <b-form-group
                                                    label="Nachname"
                                                    label-for="slastname"
                                            >
                                                <b-form-input
                                                        id="slastname"
                                                        type="text"
                                                        v-model="shippingAdr.lastname"
                                                        :required="!sameadr"
                                                >
                                                </b-form-input>
                                            </b-form-group>

                                        </div>
                                        <!--Grid column-->
                                    </div>
                                    <!--Grid row-->
                                    <b-form-group
                                            label="Firma"
                                            label-for="scompany"
                                    >
                                        <b-form-input
                                                id="scompany"
                                                v-model="shippingAdr.company"
                                                type="text"
                                        >
                                        </b-form-input>
                                    </b-form-group>
                                    <!--email-->
                                    <b-form-group
                                            label="E-Mail-Adresse"
                                            label-for="semail"
                                    >
                                        <b-form-input
                                                id="semail"
                                                v-model="shippingAdr.email"
                                                type="email"
                                                :required="!sameadr"
                                        >
                                        </b-form-input>
                                    </b-form-group>
                                    <div class="row">
                                        <!--Grid column-->
                                        <div class="col-md-10 mb-4">
                                            <b-form-group
                                                    label-for="sstreet"
                                                    label="Strasse"
                                            >
                                                <b-form-input
                                                        id="sstreet"
                                                        v-model="shippingAdr.street"
                                                        :required="!sameadr"
                                                >
                                                </b-form-input>
                                            </b-form-group>
                                        </div>
                                        <div class="col-md-2 mb-4">
                                            <b-form-group
                                                    label-for="sstreet_no"
                                                    label="Nr."
                                            >
                                                <b-form-input
                                                        id="street_no"
                                                        v-model="shippingAdr.street_no"
                                                        :required="!sameadr"
                                                >
                                                </b-form-input>
                                            </b-form-group>
                                        </div>
                                    </div>


                                    <!--Grid row-->
                                    <div class="row">

                                        <!--Grid column-->
                                        <div class="col-md-4 mb-4">
                                            <b-form-group
                                                    label-for="spostalcode"
                                                    label="PLZ"
                                            >
                                                <b-form-input
                                                        v-model="shippingAdr.postalcode"
                                                        id="spostalcode"
                                                        :required="!sameadr"
                                                >
                                                </b-form-input>
                                            </b-form-group>
                                        </div>
                                        <div class="col-md-8 mb-4">
                                            <b-form-group
                                                    label-for="scity"
                                                    label="Ort"
                                            >
                                                <b-form-input
                                                        v-model="shippingAdr.city"
                                                        id="scity"
                                                        :required="!sameadr"
                                                >

                                                </b-form-input>
                                            </b-form-group>
                                        </div>
                                        <!--Grid column-->
                                    </div>

                                    <b-form-group
                                            label="Land"
                                            label-for="scountry"
                                    >
                                        <b-form-select
                                                id="scountry"
                                                v-model="shippingAdr.country_id"
                                                :options="{DE:'Deutschland'}"
                                                :required="!sameadr"
                                                class="form-control"
                                        >
                                        </b-form-select>
                                    </b-form-group>
                                    <hr>
                                </div>

                                <b-btn color="primary" size="lg" block type="submit">Weiter</b-btn>

                            </b-form>
                            </b-card-text>
                        </b-tab>
                        <b-tab id='paymentTab' title="Zahlung abschliessen" title-item-class="mytab" >
                            <b-card-sub-title>Jetzt kostenpflichtig bestellen</b-card-sub-title>
                            <b-card-text>
                                <b-form>
                                    <b-form-group>
                                        <b-form-checkbox
                                                id="acceptTerms"
                                                v-model="showPayment"
                                        >
                                            Ich akzeptiere die <b-link :href="agbUrl" target="_blank">AGB</b-link> der Firma {{company}}
                                        </b-form-checkbox>
                                    </b-form-group>
                                </b-form>
                                <paypal-buttons
                                        v-show="showPayment"
                                >
                                </paypal-buttons>
                                <invoice-button
                                    v-show="showPayment&&invoicing"
                                >

                                </invoice-button>
                            </b-card-text>
                        </b-tab>
                    </b-tabs>
                    </b-card>
                </b-col>
                <b-col lg="4" class="mb-4">

                    <!--Card-->
                   <side-basket></side-basket>
                    <!--/.Card-->
                </b-col>
            </b-row>
        </b-card-body>
    </b-card>
    </b-overlay>
</template>



<script>
import PaypalButtons from "../components/Paypal";
import SideBasket from "../components/SideBasket";
import InvoiceButton from "@/components/PayOnInvoice";

export default {
    name: "CheckOut",
    components: {InvoiceButton, SideBasket, PaypalButtons},
    data(){
        return{
            sameadr:true,
            showPayment:false,
            tabIndex:0,
            formValidated:false,
            showOverlay:false
        }
    },
    methods:{
        validateAdress:function(event){
            event.preventDefault()
            this.formValidated=true
            this.dispatchAdresses()
            this.tabIndex=1
        },
        tabActivated(newTabIndex,oldTabIndex, event) {
            if (newTabIndex === 1 ) {
                this.formValidated=this.$refs.adrForm.checkValidity()
                if(!this.formValidated) {
                    event.preventDefault();
                }
                else{
                    this.dispatchAdresses()
                }
            }
            if(newTabIndex===0){
                this.formValidated=false
            }
        },
        dispatchAdresses(){
            this.$store.dispatch('cart/setBillingAdr',this.billingAdr)
            this.$store.dispatch('cart/setShippingAdr',this.shippingAdr)
            this.$store.dispatch('cart/setUseShipping',!this.sameadr)
        }
    },
    computed:{
        company(){
            if(this.$store.getters['inits/storeConfig']){
                return this.$store.getters['inits/storeConfig'].company
            }
            else{
                return "..lade"
            }
        },
        paymentInProgress(){
            return this.$store.getters['cart/paymentInProgress']
        },
        billingAdr(){
            return this.$store.getters['cart/billingAdr']
        },
        shippingAdr(){
            return this.$store.getters['cart/shippingAdr']
        },
        agbUrl() {
            return this.$store.getters['inits/agbUrl']
        },
        invoicing(){
            return (this.$store.getters['inits/storeConfig'].invoicing===1)&&(this.$store.getters['cart/billingAdr'].invoicing===1)
        }
    }
}
</script>

<style>
    .nav-pills .mytab .nav-link:not(.active) {
        background-color: transparent;
        /*border-color: */
        color:lightgrey;
    }
    .nav-pills .mytab .nav-link:not(.active):hover{
        color:darkgrey;
    }


    .nav-pills .mytab .nav-link {
        background-color: #5a6268;
        border-color:#5a6268;
    }







</style>